import CookieNames from '@/constants/CookieNames';
import FeatureFlags from '@/constants/FeatureFlags';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import bam from '@/lib/bam';
import { getCookie, setCookie } from '@/lib/cookie-helpers';
import getBillingPeriodShortCopy from '@/modules/Billing/helpers/getBillingPeriodShortCopy';
import { StaffMember, StaffStates } from '@/modules/StaffManagement/models';
import launchDarkly from '@bambeehr/vue-launch-darkly';
import {
  computed,
  ComputedRef,
  ref,
  useStore,
  watch,
} from '@nuxtjs/composition-api';
import useStaffManagement from '../../hooks/useStaffManagement';
import { ProductKey } from '@/gql/generated';

const isModalOpen = ref(false);

const useTierUpgradeNotice = () => {
  const store = useStore();
  const isTierUpgradeNoticeEnabled = computed(() => {
    return launchDarkly.getFlags()[FeatureFlags.AUTO_UPGRADE_TIER];
  });

  const { company, isTrial } = useCurrentCompany();
  const { allStaff } = useStaffManagement();

  // only active, invited, not invited, w2 employees should be counted as a seat
  const staffMembers: ComputedRef<StaffMember[]> = computed(() =>
    allStaff.value.filter(
      (staffMember: StaffMember) =>
        staffMember.staffState !== StaffStates.FORMER && !staffMember.contractor
    )
  );

  const plans = computed(() => store.getters['pricePlans/all']);

  // filter out the paused plans
  const pricePlans = computed(() =>
    plans.value.filter((plan) => plan.pause === false)
  );

  const dismissedCookieName = `${company.value.id}_${CookieNames.DISSMISSED_TIER_UPGRADE_MODAL}`;
  const dismissedCookie = ref(getCookie(dismissedCookieName));

  const currentPlan = computed(() =>
    pricePlans.value.find(
      (plan: { value: string | undefined }) => plan.value === company.value.plan
    )
  );

  const withinRange = (min: number, max: number) => (num: number) =>
    num >= min && num <= max;

  const currentPlanIntervalPrice = computed(() => {
    const period = getBillingPeriodShortCopy(currentPlan.value.billingPeriod);

    return `${currentPlan.value.price}/${period}`;
  });

  // get the next plan based on the staff count
  const planByEmployeeCount = computed(() => {
    return pricePlans?.value?.find(
      (plan: {
        volumeBased: boolean;
        billingPeriod: string;
        internal: boolean;
        partner: string;
        productKey: ProductKey;
        minSize: number;
        maxSize: number;
        status: string;
        paused: boolean;
        version: string;
        value: string;
      }) => {
        // internal plans (e.g. DRP) should not be exposed
        const isInternalPlan = plan.internal;

        const isWithinRange = withinRange(
          plan.minSize,
          plan.maxSize
        )(staffMembers.value?.length);

        const matchesProductKey =
          plan.productKey === currentPlan.value.productKey;

        const matchesBillingPeriod =
          plan.billingPeriod === currentPlan.value.billingPeriod;

        const matchesPartner = plan.partner === currentPlan.value.partner;
        // Tier 0 are not volume based but are upgraded to a volume based plan
        if (currentPlan.value.tierNumber === 0) {
          const isActive = plan.status === 'active';

          return (
            matchesProductKey &&
            matchesBillingPeriod &&
            matchesPartner &&
            isWithinRange &&
            isActive &&
            !isInternalPlan
          );
        }

        // Any core tiered plan - see plans in Stripe
        if (currentPlan.value?.volumeBased) {
          const matchesVersion = plan.version === currentPlan.value.version;

          return (
            matchesProductKey &&
            matchesBillingPeriod &&
            matchesPartner &&
            isWithinRange &&
            matchesVersion &&
            !isInternalPlan
          );
        }

        // return the same plan where not volume based aka grandfathered in
        return plan.value === currentPlan.value.value;
      }
    );
  });

  const planByEmployeeCountIntervalPrice = computed(() => {
    const period = getBillingPeriodShortCopy(
      planByEmployeeCount.value.billingPeriod
    );

    return `${planByEmployeeCount.value.price}/${period}`;
  });

  // Banner should show if the customer has more than the max size of the current plan
  // and don't show the modal if the current plan is "grandfathered" in. aka volumeBased = false
  // only show the banner if the launch darkly flag is true
  const shouldShowBanner = computed(
    () =>
      staffMembers.value.length > currentPlan.value?.maxSize &&
      (currentPlan.value?.volumeBased || currentPlan.value?.tierNumber === 0) &&
      isTierUpgradeNoticeEnabled.value
  );

  watch(
    staffMembers,
    () => {
      // if the number of employees is greater than the current plan than show the upgrade notice modal
      // and don't show the modal if the current plan is "grandfathered" in. aka volumeBased = false
      // and don't show the modal if the user has dismissed the modal.
      if (
        staffMembers.value.length > currentPlan.value?.maxSize &&
        (currentPlan.value?.volumeBased ||
          currentPlan.value.tierNumber === 0) &&
        !dismissedCookie.value &&
        isTierUpgradeNoticeEnabled.value
      ) {
        isModalOpen.value = true;
        bam.track('modal_shown', {
          company_id: company.value.id,
          modal_name: 'user-count-overage-warning',
          timestamp: new Date(),
        });
      }
    },
    { immediate: true }
  );

  function handleModalClose() {
    isModalOpen.value = false;
    // if the user closes the modal we won't show them again.
    // cookie expires in 2 days
    setCookie(dismissedCookieName, true, { expires: 2 });
  }

  function openModal() {
    isModalOpen.value = true;
  }

  return {
    handleModalClose,
    staffMembers,
    isModalOpen,
    currentPlan,
    currentPlanIntervalPrice,
    planByEmployeeCount,
    planByEmployeeCountIntervalPrice,
    shouldShowBanner,
    openModal,
    isTrial,
  };
};

export default useTierUpgradeNotice;
