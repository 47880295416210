import Store from '@/constants/Store';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import Vue from 'vue';
import {
  ModelNames as CapModelNames,
  Statuses as CapStatuses
} from '../lib/globals/capTypes';

export const state = () => ({
  caps: {},
  capIdsByUserId: {},
});

const getUserId = (state) => {
  return get(state, 'current_user._id');
};

export const mutations = {
  setCaps(state, { userId, capFeeds }) {
    const newCaps = keyBy(capFeeds, '_id');
    const userCapIds = Object.keys(newCaps);

    state.caps = {
      ...state.caps,
      ...newCaps,
    };

    Vue.set(state.capIdsByUserId, userId, userCapIds);
  },

  udpateCAP(state, { feedId, data }) {
    if (state.caps[feedId]) {
      Vue.set(state.caps[feedId], '_data', data);
    }
  },

  removeCAP(state, { feedIds, userId }) {
    const newFeeds = state.capIdsByUserId[userId].filter(
      (feedId) => !feedIds.includes(feedId)
    );

    Vue.set(state.capIdsByUserId, userId, newFeeds);
  },
};

export const getters = {
  capsForUserInScope(state, getters, rootState) {
    const userId = getUserId(rootState);

    if (!state.capIdsByUserId[userId]) {
      return [];
    }

    return state.capIdsByUserId[userId]
      .map((capId) => state.caps[capId])
      .filter((feed) => feed._data && feed._employee);
  },

  caps(state, getters, rootState, rootGetters) {
    return getters.capsForUserInScope.filter((feed) => {
      const cap = feed._data;
      if (!CapModelNames.includes(feed.model)) {
        return false;
      }

      if (feed.model === 'Verbalwarning') {
        return true;
      }

      if (!cap.steps) {
        return false;
      }

      if (rootGetters[Store.auth.Getters.IS_COMPANY_ADMIN]) {
        if (cap.isFinal && !cap.steps[cap.steps.length - 1].active) {
          return true;
        }

        return cap.status !== CapStatuses.SIGNATURE;
      }

      // only final written warnings have 7 CAP steps
      // all other CAPs have 5 steps
      // only show final written warning for employees if on the cap has reached the LAST step
      // cap.steps[6] is the 'employee_sign' step for final written warnings
      return cap.steps[cap.steps.length - 1].active;
    });
  },

  capsCount(state, getters) {
    return getters.caps.length;
  },
};

export const actions = {
  async fetchCAPs({ commit, rootState, rootGetters: { companyId } }) {
    const userId = getUserId(rootState);

    try {
      const res = await this.$axios.get(
        `/v0/companies/v1/company/${companyId}/caps`
      );

      const payload = {
        userId,
        capFeeds: res.data.capFeeds,
      };

      commit('setCaps', payload);
    } catch (e) {
      console.log(e);
    }
  },

  addCAPs({ commit }, payload) {
    commit('setCaps', payload);
  },

  async deleteCAP({ commit, rootState }, { id, type, feedId }) {
    const userId = getUserId(rootState);

    try {
      await this.$axios.delete(`/v0/caps/v1/cap/${type}/${id}`);

      commit('removeCAP', {
        feedIds: [feedId],
        userId,
      });
    } catch (e) {
      console.log(e);
    }
  },

  updateCAP({ commit, getters }, { data }) {
    const feed = getters.capsForUserInScope.find(
      (f) => f._data._id === data._id
    );

    if (feed) {
      commit('udpateCAP', {
        feedId: feed._id,
        data,
      });
    }
  },
};
