























































































import {
  defineComponent,
  ref,
  PropType,
  computed,
  watch,
} from '@nuxtjs/composition-api';
import VueUploadComponent from 'vue-upload-component';
import { BaseButton, TypeBody, BaseIcon } from '@bambeehr/pollen';
import getCommaList from '@/utils/getCommaList';
import convertBytesToUnits from '@/utils/convertBytesToUnits';
import validateFile from './utils/validateFile';
import getFileTypeFromMime from './utils/getFileTypeFromMime';
import isEqual from 'lodash/isEqual';

export const getUploaderDescription = (
  maxBytes: number,
  extensions: string[]
) =>
  `Uploaded files must be ${getCommaList(
    extensions.map((i) => i.toUpperCase()),
    'or'
  )} and cannot exceed ${convertBytesToUnits(maxBytes, 0)} in size`;

export default defineComponent({
  name: 'FileUpload',
  components: {
    VueUploadComponent,
    BaseButton,
    TypeBody,
    BaseIcon,
  },
  props: {
    extensions: {
      type: Array as PropType<string[]>,
      default: () => ['jpg', 'png', 'pdf'],
    },
    accept: {
      type: String,
      default: 'image/png,image/jpeg,application/pdf',
    },
    sizeLimit: {
      type: Number,
      // Default of 5MB
      default: 1024 ** 2 * 5,
    },
    maxCount: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: 'Upload your document below',
    },
    error: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    // Any because the uploader doesn't have types
    const uploader = ref<any>(null);
    const uploads = ref<any[]>([]);
    const validationError = ref('');

    const description = computed(() =>
      getUploaderDescription(props.sizeLimit, props.extensions)
    );

    const maxFilesReached = computed(
      () => uploads.value.length === props.maxCount
    );

    const isMultiple = computed(() => props.maxCount > 1);

    const removeFile = (file) => {
      if (uploader.value) {
        uploader.value?.remove(file);
      }
    };

    // Listen for updates from parent components
    watch(
      props,
      ({ value }) => {
        if (!isEqual(value, uploads.value)) {
          uploads.value = value;
        }
      },
      {
        deep: true,
        immediate: true,
      }
    );

    watch(uploads, (files) => {
      files.forEach((file) => {
        const err = validateFile(file, props.sizeLimit, props.accept);

        if (err) {
          validationError.value = err;
          removeFile(file);
        }
      });

      emit('input', files);
    });

    // Sets a timeout to clear the validation error after 15 seconds per product spec
    // Prevents error from persisting after user has fixed the issue since this is a post-state / cached error
    watch(validationError, (error) => {
      setTimeout(() => {
        if (error === validationError.value) {
          validationError.value = '';
        }
      }, 15000);
    });

    const uploaderError = computed(() => props.error || validationError.value);

    return {
      description,
      getFileTypeFromMime,
      isMultiple,
      maxFilesReached,
      removeFile,
      uploader,
      uploaderError,
      uploads,
    };
  },
});
