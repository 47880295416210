/* eslint-disable @typescript-eslint/no-shadow */
import { CookieNames } from '@/constants/Cookies';
import Store from '@/constants/Store';
import bam from '@/lib/bam';
import {
  deleteAccessTokenCookies,
  getCookie,
  setCookie,
} from '@/lib/cookie-helpers';
import {
  dateForServer,
  dateOnlyFromServer,
  phoneForServer,
  phoneFromServer,
} from '@/lib/normalization';
import get from 'lodash/get';
import Vue from 'vue';

export const state = () => ({
  current_user: null,
  company_welcomed: null,
  showTermsOfService: false,
  showedTermsOfService: false,
  // TODO(jon.jandoc) This is only being used in src/pages/employee-welcom.
  // Consider moving to local data state for that page.
  welcome_employee_wizard: {
    steps: [
      {
        title: 'Setup Profile',
        icon: '/assets/images/d-icon-set/house+heart/fill.svg',
        url: '/set-password',
        active: true,
        done: false,
      },
      {
        title: 'Meet HR Rep',
        icon: '/assets/images/d-icon-set/person/female/fill.svg',
        url: '/meet-hr',
        active: false,
        done: false,
      },
      {
        title: 'Sign Documents',
        icon: '/assets/images/d-icon-set/pencil/fill.svg',
        url: '/ask-hr',
        active: false,
        done: false,
      },
    ],
  },

  crumbs: [],

  hrExpectations: [],

  legacyScheduler: {
    show: false,
    type: null,
  },

  calendlyLeadFired: false,
  calendlyPersonalLeadFired: false,
});

export const getters = {
  // TODO(jon.jandoc): Remove. Redundant.
  currentUser(state) {
    return state.current_user;
  },
  // TODO(jon.jandoc): Move to auth module.
  company(state) {
    return get(state.current_user, '_company', null);
  },

  // TODO(jon.jandoc): Move to auth module.
  companyId(state) {
    return get(state, 'current_user._company._id', null);
  },

  // TODO(jon.jandoc): This appears to only be used in src/components/settings.
  // We can turn this into a computed property on that component.
  hidePayRate(state, getters, rootState, rootGetters) {
    if (rootGetters[Store.auth.Getters.IS_OWNER]) {
      return false;
    }

    return state.current_user._company.settings.hidePayRate;
  },

  isChatOnly(state, getters, rootState, rootGetters) {
    const planId = get(state.current_user, '_company.plan');
    const pricePlans = rootGetters['pricePlans/all'];

    return !!(pricePlans.find(({ value }) => value === planId) || {}).chatOnly;
  },

  // TODO(jon.jandoc): Leave here for now, but needs updated references to
  // current user.
  showHRExpectations(state) {
    if (!state.current_user) {
      return false;
    }

    const company = state.current_user._company;

    if (!company) {
      return false;
    }

    const { role } = state.current_user;

    if (role !== 'user') {
      return false;
    }

    if (company.states.acknowledgedHRExpectation) {
      return false;
    }

    return true;
  },

  // TODO(jon.jandoc): Remove. Redundant.
  hrExpectations(state) {
    return state.hrExpectations;
  },

  // TODO(jon.jandoc): Move to the insurance store module.
  viewedInsuranceIntroduction(state) {
    return (
      get(state.current_user, 'states.viewedInsuranceIntroduction') ||
      getCookie('viewedInsuranceIntroduction') ||
      false
    );
  },

  // TODO(jon.jandoc): Move to auth module.
  getMyState: (state) => (key) => {
    return get(state.current_user, `states.${key}`) || false;
  },

  // TODO(jon.jandoc): Move to the insurance store module.
  insuranceEnabled(state) {
    return state.current_user._company.settings.insurance_enabled;
  },

  // TODO(jon.jandoc): Move to the insurance store module.
  isInterestedInPersonalInsurance(state, getters) {
    if (state.calendlyPersonalLeadFired) {
      return true;
    }

    const { _auth } = getters.currentUser;

    return _auth.profile && _auth.profile.is_interested_in_insurance;
  },
};

export const mutations = {
  setCurrentUser(state, user) {
    // TODO(jon.jandoc): We should be cloning the `user` object as we don't want
    // to be mutating one of the arguments of a function.
    if (user && user.profile) {
      user.profile.dob = dateOnlyFromServer(user.profile.dob);
      user.profile.start_date = dateOnlyFromServer(user.profile.start_date);
      user.profile.phone = phoneFromServer(user.profile.phone);
      if (user.profile.emergency_contact) {
        user.profile.emergency_contact.phone = phoneFromServer(
          user.profile.emergency_contact.phone
        );
      }
    }

    state.current_user = user;
  },

  // TODO(jon.jandoc): Is this because a user can belong to multiple companies
  // and switch between those companies? This isn't great. We shouldn't be
  // mutating properties of the user object. Instead we should be leveraging the
  // data map hash in the `companies` store module, and maybe add a state there
  // for "currentComapny".
  setCurrentCompany(state, company) {
    if (state.current_user) {
      state.current_user._company = company;
    } else {
      state.company_welcomed = company;
    }
  },

  // TODO(jon.jandoc): This is only used in src/pages/c. We should move this to
  // local data state for that page.
  setCompanyWelcomed(state, company) {
    state.company_welcomed = company;
  },

  // TODO(jon.jandoc): This isn't being used anywhere. Remove.
  setCurrentPlan(state, plan) {
    state.current_user._company.plan = plan;
  },

  // TODO(jon.jandoc) This is only being used in src/pages/employee-welcom.
  // Consider moving to local data state for that page.
  setWelcomeStepDone(state, index) {
    const current = state.welcome_employee_wizard.steps[index];
    const next = state.welcome_employee_wizard.steps[index + 1];

    current.done = true;

    if (next) {
      next.active = true;
    }
  },

  // TODO(jon.jandoc) This is only being used in src/pages/employee-welcom.
  // Consider moving to local data state for that page.
  setWelcomeStepDoneRefreshed(state, url) {
    const { steps } = state.welcome_employee_wizard;
    const stepNum = steps.findIndex((s) => s.url === url);
    const newStep = steps.map((s, index) => ({
      ...s,
      active: s.url === url,
      done: index < stepNum,
    }));
    state.welcome_employee_wizard.steps = newStep;
  },

  setCrumbs(state, crumbs) {
    state.crumbs = crumbs;
  },

  // TODO(jon.jandoc): Move to the bci store module.
  dismissBci(state) {
    state.current_user._company.settings.bci_dismissed = true;
  },

  // TODO(jon.jandoc): Move to the company store.
  setCompanyProfileKey(state, { key, value }) {
    state.current_user._company.profile[key] = value;
  },

  // TODO(jon.jandoc): Move to the company store? Insurance store?
  setCompanyReportedInsuranceKey(state, { key, value }) {
    state.current_user._company.self_reported_insurance[key] = value;
  },

  // TODO(jon.jandoc): Move to the insurance store.
  updateInsuranceInterest(state, data) {
    state.current_user._company.profile.insuranceInterest =
      data.insuranceInterest;
    state.current_user._company.profile.insuranceInterestLines =
      data.insuranceInterestLines;
  },

  // TODO(jon.jandoc): This can probably be moved to src/layouts/default
  showTermsOfService(state, bool) {
    state.showTermsOfService = bool;
    state.showedTermsOfService = true;
  },

  setHRExpectations(state, data) {
    state.hrExpectations = data;
  },

  // TODO(jon.jandoc): This and all the "legacyScheduler" stuff can probably be
  // moved to its own component or pulled into src/layouts/default.
  setSchedulerOn(state, type) {
    state.legacyScheduler.show = true;
    state.legacyScheduler.type = type;
  },

  resetScheduler(state) {
    state.legacyScheduler.show = false;
    state.legacyScheduler.type = null;
  },
  setCalendlyEvent(state, type) {
    const types = {
      lead: 'calendlyLeadFired',
      personalInsurance: 'calendlyPersonalLeadFired',
    };
    const key = types[type];

    if (key) {
      state[key] = true;
    }
  },

  // TODO(jon.jandoc): Move this to the auth module, though we shouldn't be
  // directly mutating the user object.
  setState(state, { key, value }) {
    Vue.set(state.current_user.states, key, value);
  },
};

export const actions = {
  // TODO(jon.jandoc): Move to auth module.
  refreshSelf({ commit }) {
    return this.$axios.get('/v0/app/my/self').then((res) => {
      const user = res.data;
      commit('setCurrentUser', user);

      return user;
    });
  },

  // TODO(jon.jandoc): Move to auth module.
  async updateSelf({ commit }, { profile }) {
    // TODO(jon.jandoc): We shouldn't be mutating an argument.
    // remove the virtual field so it doesn't update first and last names
    delete profile.full_name;
    // sanitize dates and phones
    profile.dob = dateForServer(profile.dob);
    profile.start_date = dateForServer(profile.start_date);
    profile.phone = phoneForServer(profile.phone);
    if (profile.emergency_contact) {
      profile.emergency_contact.phone = phoneForServer(
        profile.emergency_contact.phone
      );
    }

    // send request
    const res = await this.$axios.post('/v0/app/my/self/update-profile', {
      profile,
    });
    const user = res.data;
    commit('setCurrentUser', user);

    return user;
  },

  // TODO(jon.jandoc): Move to companies or auth module
  async updateSelfCompany({ commit }, { company }) {
    const { data } = await this.$axios.post(
      '/v0/app/my/self/update-company-profile',
      {
        company,
      }
    );
    commit('setCurrentCompany', data);

    return data;
  },

  // TODO(jon.jandoc): Move to companies or auth module.
  acknowledgeExpectation({ commit }) {
    return this.$axios
      .post('/v0/app/my/self/acknowledge-expectation')
      .then((res) => {
        const company = res.data;

        commit('setCurrentCompany', company);

        return company;
      });
  },

  // TODO(jon.jandoc): Move to auth module.
  logout({ commit }) {
    const thisUrl = new URL(window.location);
    const redirectUrl = encodeURIComponent(
      `${thisUrl.protocol}//${thisUrl.host}/login`
    );

    deleteAccessTokenCookies();
    sessionStorage.clear();
    bam.shutdown();
    bam.initIntercom();

    // bounce to https://bambee.com/logout.html to delete any cookies on the `.bambee.com` domain.
    window.location.replace(
      `https://bambee.com/logout.html?env=${process.env.APP_ENV}&app=app&redirectUrl=${redirectUrl}`
    );
  },

  // TODO(jon.jandoc): Move to auth module.
  updateUser({ commit }, { user }) {
    return this.$axios
      .post(`/v0/app/user/${user.id}/update`, { user })
      .then((res) => {
        commit('setCurrentUser', res.data);

        return res.data;
      });
  },

  // TODO(jon.jandoc): Move to auth module.
  updatePassword({ commit }, { new_password: password }) {
    return this.$axios
      .post(`/v0/app/my/self/update-password`, { password })
      .then((res) => {
        const { token, user } = res.data;
        commit('setCurrentUser', user);

        setCookie(CookieNames.ACCESS_TOKEN, token);

        return res.data;
      });
  },

  // TODO(jon.jandoc): Move to auth module.
  updateEmailNotificationSettings({ commit }, notificationSetting) {
    return this.$axios
      .post(`/v0/app/my/self/update-email-notification-settings`, {
        notificationSetting,
      })
      .then((res) => {
        commit('setCurrentUser', res.data);

        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  // TODO(jon.jandoc): This doesn't do anything to the store. Can we move this
  // to a services module?
  // todo: write test for this route
  managerCanGrade({ commit }, managerCanGrade) {
    return this.$axios
      .post(`/v0/app/my/self/manager-can-grade`, { managerCanGrade })
      .then((res) => {
        commit('setCurrentUser', res.data);

        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  // TODO(jon.jandoc): Are we using this to get other users? In which case this
  // should be moved to the `users` store module. If we only want this to get
  // the current user's data, can we drop this in favor of the `refreshSelf`
  // action?
  getUser({ commit }, { user_id: id }) {
    return this.$axios
      .get(`/v0/app/user/${id}`)
      .then((res) => {
        commit('setCurrentUser', res.data);

        return res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },

  // TODO(jon.jandoc): Move to auth module.
  setNewUserPassword({ commit }, { password, token }) {
    return this.$axios
      .post('/v0/app/user/set-new-user-password', {
        password,
        token,
      })
      .then((res) => {
        commit('setCurrentUser', res.data);
      });
  },

  // TODO(jon.jandoc): Move to auth module.
  resetForgottenPassword(_, { password, token }) {
    return this.$axios
      .post('/v0/user/reset-password', {
        password,
        token,
      })
      .then((res) => {
        console.log('success', res);
      });
  },

  // TODO(jon.jandoc): Move to company module.
  getCompanyOverview({ commit }) {
    return this.$axios
      .post('/v0/app/company/get-company-overview')
      .then((res) => {
        commit('setCurrentCompany', res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

  // TODO(jon.jandoc): Move to auth module.
  async activateAccount({ commit }, { email, password, phoneNumber, profile }) {
    const v1Response = await this.$axios.post('/v0/app/my/activate-account', {
      email,
      password,
      phoneNumber,
      profile,
    });

    const token = v1Response.data.accessToken || v1Response.data.token;
    commit('setCurrentUser', v1Response.data.user);
    setCookie(CookieNames.ACCESS_TOKEN, token);

    return v1Response.data;
  },

  // TODO(jon.jandoc): Move to auth module, maybe?
  acceptTermsOfService({ commit }) {
    return this.$axios.post('/v0/app/my/self/accept-tos').then((res) => {
      commit('showTermsOfService', false);
      commit('setCurrentUser', res.data);
    });
  },

  // TODO(jon.jandoc): Move to auth module? Maybe we call the "auth" module the
  // "currentUser" module?
  enableReportCards({ commit }) {
    return this.$axios
      .post('/v0/app/my/self/enable-report-cards')
      .then((res) => {
        commit('setCurrentUser', res.data);
      });
  },

  // TODO(jon.jandoc): Move to auth module.
  async customerRegister({ commit }, { user, company }) {
    try {
      const res = await this.$axios.post('/v0/customer/register', {
        user,
        company,
      });

      const { user: newUser, accessToken, refreshToken } = res.data;
      commit('setCurrentUser', newUser);
      commit('setLinkedInPixel', true);
      setCookie(CookieNames.ACCESS_TOKEN, accessToken);
      setCookie(CookieNames.REFRESH_TOKEN, refreshToken);

      bam.identify(newUser);
      bam.track('Lead', {
        value: '0.01',
        currency: 'USD',
      });

      return newUser;
    } catch (err) {
      const errorMessage =
        err.response && err.response.status === 409
          ? 'Duplicate email or phone number'
          : err;
      throw new Error(errorMessage);
    }
  },

  // TODO(jon.jandoc): Maybe HR Expectations should be its own store module?
  getHRExpectations({ commit }) {
    return this.$axios.get('/v0/app/config/hr-expectations').then((res) => {
      const nameTypeMapping = {
        hr_questions: 'HR Questions',
        claims: 'Department of Labor Claims',
        'job-description': 'Job Descriptions',
        'new-policy': 'Policies',
        handbook: 'Handbook',
        terminate: 'Terminate Employee',
        wagehour: 'Wage & Hour Question',
        employees: 'Employee Issue',
        general: 'General HR & Compliance Question',
        'training-development': 'Training & Development',
        poster: 'Labor Poster Request',
      };

      const expectation = res.data.expectedTime.map((expect) => {
        return {
          id: expect._id,
          type: expect.name,
          name: nameTypeMapping[expect.name],
          duration: expect.time,
          description: expect.description,
        };
      });

      commit('setHRExpectations', expectation);

      return expectation;
    });
  },

  // TODO(jon.jandoc): Move to bci store module.
  async dismissBci({ commit }) {
    const companyId = this.state.current_user._company._id;

    await this.$axios.post(
      `/v0/companies/v1/company/${companyId}/settings/dismiss-bci`,
      {}
    );
    commit('dismissBci');
  },

  // TODO(jon.jandoc): Move to insurance module.
  markInsuranceInterest(
    { commit },
    { line, interestSource = 'app', answer = true }
  ) {
    const companyId = this.state.current_user._company._id;
    let actionPath = `/v0/companies/v1/company/${companyId}/insurance/interest`;
    if (line) {
      actionPath += `/v0/${line}`;
    }

    return this.$axios
      .post(`${actionPath}?interestSource=${interestSource}`, {
        interested: answer,
      })
      .then((res) => {
        commit('updateInsuranceInterest', res.data);
      });
  },

  // TODO(jon.jandoc): Move to insurance module.
  markInsuranceIntroductionViewed({ commit }) {
    setCookie('viewedInsuranceIntroduction', true);
    commit('setState', {
      key: 'viewedInsuranceIntroduction',
      value: true,
    });
  },

  // TODO(jon.jandoc): Move to insurance module.
  updateCompanySelfReportedInsuranceKey(
    { commit, state },
    { key, carrierName, value }
  ) {
    const companyId = state.current_user._company._id;

    commit('setCompanyReportedInsuranceKey', {
      key,
      value,
    });

    if (value === true) {
      return this.$axios.post(
        `/v0/companies/v1/company/${companyId}/self-reported-insurance`,
        {
          key,
          carrierName,
        }
      );
    }

    return this.$axios.delete(
      `/v0/companies/v1/company/${companyId}/self-reported-insurance`,
      {
        params: {
          key,
        },
      }
    );
  },

  // TODO(jon.jandoc): Move to companies module.
  updateCompanyProfileKey({ commit, state }, { key, value }) {
    const companyId = state.current_user._company._id;

    commit('setCompanyProfileKey', {
      key,
      value,
    });

    return this.$axios.patch(`/v0/companies/v1/company/${companyId}/profile`, {
      key,
      value,
    });
  },

  // TODO(jon.jandoc): Move to auth module.
  // User State
  updateState({ commit, getters }, { key, value }) {
    if (getters.getMyState(key)) {
      return Promise.resolve();
    }

    return this.$axios
      .patch(`/v0/my/v1/state`, {
        key,
        value,
      })
      .then(() => {
        const newValue = value || true;
        commit('setState', {
          key,
          value: newValue,
        });
      });
  },
};
