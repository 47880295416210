










import { defineComponent } from '@nuxtjs/composition-api';
import MarketingBanner from '@/components/MarketingBanner/MarketingBanner.vue';

import { BaseButton } from '@bambeehr/pollen';

export default defineComponent({
  name: 'TierUpgradeNoticeBanner',
  components: {
    MarketingBanner,
    BaseButton,
  },
});
