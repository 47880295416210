import {
  HrRequestGroupKey,
  HrRequestOption,
  HrRequestSlug,
  HrRequestSubType,
  HrRequestType,
} from '@/gql/generated';

export interface PopularRequestOption extends HrRequestOption {
  icon: string;
}

export interface RequestOption extends HrRequestOption {
  icon: string;
  credits: number;
  activeSubscription: boolean;
}

const CUSTOMER_SERVICE_TYPES = [
  HrRequestType.AdminSupport,
  HrRequestType.TechSupport,
  HrRequestType.Billing,
];

const CUSTOMER_SERVICE_SUB_TYPES = [
  HrRequestSubType.AppHelp,
  HrRequestSubType.MissedPayroll,
];

const CUSTOMER_SERVICE_TYPES_API_NAMES = {
  [HrRequestType.AdminSupport]: 'ADMIN_SUPPORT',
  [HrRequestType.TechSupport]: 'TECH_SUPPORT',
  [HrRequestType.Billing]: 'BILLING',
  [HrRequestType.BambeePayroll]: 'BAMBEE_PAYROLL',
};

const CUSTOMER_SERVICE_SUB_TYPE_API_NAMES = {
  [HrRequestSubType.AppHelp]: 'APP_HELP',
  [HrRequestSubType.MissedPayroll]: 'MISSED_PAYROLL',
};

const ADMIN_SUPPORT_SUB_TYPE_ANSWERS = Object.freeze({
  'Time and Attendance': HrRequestSubType.TimeAndAttendance,
});

const PAYROLL_SUB_TYPE_ANSWERS = Object.freeze({
  'How do I use Bambee Payroll?': HrRequestSubType.AppHelp,
  'Where do I find paystubs or reports?': HrRequestSubType.AppHelp,
  'Where do I find tax documents?': HrRequestSubType.AppHelp,
  'Adding or editing staff payroll information': HrRequestSubType.AppHelp,
  'I missed a payroll': HrRequestSubType.MissedPayroll,
  'I need to adjust an approved payroll':
    HrRequestSubType.AdjustApprovedPayroll,
  'Add or edit deductions, benefits, or garnishments':
    HrRequestSubType.TaxQuestion,
  'I need to file a tax amendment': HrRequestSubType.TaxAmendment,
  'Tax questions': HrRequestSubType.TaxQuestion,
  'General payroll question': HrRequestSubType.General,
});

export const PAYROLL_PAM_EXECUTOR_ANSWERS = [
  'I need to adjust an approved payroll',
  'Add or edit deductions, benefits, or garnishments',
  'Tax questions',
  'I need to file a tax amendment',
];

export const isExecutedByPam = (answers) =>
  PAYROLL_PAM_EXECUTOR_ANSWERS.includes(answers.what_type);

export const isCustomerServiceSubType = (subType) =>
  CUSTOMER_SERVICE_SUB_TYPES.includes(subType);

export const isCustomerServiceType = (type) =>
  CUSTOMER_SERVICE_TYPES.includes(type);

export const customerServiceTypeApiName = (type: HrRequestType) =>
  CUSTOMER_SERVICE_TYPES_API_NAMES[type] || type;

export const customerServiceSubTypeApiName = (subType: HrRequestSubType) =>
  CUSTOMER_SERVICE_SUB_TYPE_API_NAMES[subType] || subType;

export const hrRequestFullSlug = ({
  groupKey,
  slug,
}: {
  groupKey: HrRequestGroupKey;
  slug: HrRequestSlug;
}): string => {
  const fullSlug = `${groupKey}-${slug}`;

  // for backward compatibility
  return fullSlug.replace(/_/g, '-').toLowerCase();
};

export const mapPayrollSurveyToSubType = (answers) => {
  return PAYROLL_SUB_TYPE_ANSWERS[answers.what_type];
};

export const mapAdminSupportSurveyToSubType = (answers) => {
  return ADMIN_SUPPORT_SUB_TYPE_ANSWERS[answers.what_is_your_request];
};

const HR_REQUEST_OPTION_SUB_TYPE_HANDLERS = {
  [HrRequestSlug.Payroll]: mapPayrollSurveyToSubType,
  [HrRequestSlug.AdminSupport]: mapAdminSupportSurveyToSubType,
};

export const mapHrRequestSlugToSubType = (
  slug: HrRequestSlug,
  answers: any
): HrRequestSubType | undefined => {
  const subTypeHandler = HR_REQUEST_OPTION_SUB_TYPE_HANDLERS[slug];

  return subTypeHandler ? subTypeHandler(answers) : undefined;
};

export enum RequestStatus {
  PROGRESS = 'In Progress',
  SUBMITTED = 'Request Submitted',
  RESOLVED = 'Resolved',
  REQUIRE_ACTION = 'Action Needed',
  CANCELLED = 'Cancelled',
  CLOSED = 'Closed',
}
