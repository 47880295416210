import Vue from 'vue';

export const state = () => ({
  conversations: [],
  active_conversation: null,
});

export const getters = {
  all(state) {
    return state.conversations;
  },
  active_conversation(state) {
    return state.active_conversation || null;
  },
};

export const mutations = {
  setConversations(state, conversations) {
    state.conversations = conversations;
  },
  addConversation(state, conversation) {
    state.conversations.unshift(conversation);
  },
  setActiveConversation(state, conversation) {
    Vue.set(state, 'active_conversation', conversation);
    // state.active_conversation = conversation
  },

  setConversation(state, conversation) {
    const index = state.conversations.findIndex((c) => c.id == conversation.id);

    if (index === -1) {
      return;
    }

    state.conversations.splice(index, 1, conversation);

    state.active_conversation = conversation;
  },
};

export const actions = {
  createConversation({ commit }, { type, ticket_id, text, chat_now }) {
    return this.$axios
      .post('/v0/app/conversation/create', {
        message: {
          type,
          ticket_id,
          text,
          chat_now,
        },
      })
      .then((res) => {
        commit('addConversation', res.data);

        return res.data;
      });
  },

  getMyConversations({ commit }) {
    return this.$axios.get('/v0/app/conversation/my').then((res) => {
      commit('setConversations', res.data);

      return res.data;
    });
  },

  getConversation({ commit }, conversation_id) {
    return this.$axios
      .get(`/v0/app/conversation/${conversation_id}`)
      .then((res) => {
        commit('setActiveConversation', res.data);

        return res.data;
      });
  },

  createReply({ commit, state }, payload) {
    return this.$axios
      .post(
        `/v0/app/conversation/${state.active_conversation.id}/add-message`,
        payload
      )
      .then((res) => {
        commit('setConversation', res.data);

        return res.data;
      });
  },
};
