import createApolloClient, {
  GRAPHQL_SERVER_URL,
} from '@/plugins/apollo/config/createApolloClient';

const getApolloClient = () => {
  return createApolloClient({
    // @ts-ignore
    httpEndpoint: `${GRAPHQL_SERVER_URL}/graphql`,
    httpOptions: {
      credentials: 'include',
    },
    retryOptions: {
      delay: {
        initial: process.env.GQL_RETRY_DELAY_INTIAL,
        max: process.env.GQL_DELAY_MAX,
      },
      attempts: {
        max: process.env.GQL_RETRY_ATTEMPTS,
      },
    },
    timeout: process.env.GQL_TIMEOUT,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
        partialRefetch: true,
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
};

const apolloClient = getApolloClient();

export default apolloClient;

export const query = async (q, variables, resultKey, options) => {
  // @ts-ignore
  const { data, errors, loading } = await apolloClient
    .query({
      query: q,
      variables,
      ...options,
    })
    .catch((err) => ({ errors: err }));

  return {
    errors,
    loading,
    data: resultKey ? data?.[resultKey] : data,
  };
};

/**
 * Generic function. Does a GraphQL watchQuery
 * @param {Object} q GraphQL query to request
 * @param {Object} variables GraphQL variables for the query
 * @param {String} resultKey The property to drill down on in the GraphQL result object
 * @returns {Observable}
 */
export const watchQuery = (q, variables, resultKey) => {
  return apolloClient
    .watchQuery({
      query: q,
      variables,
    })
    .map((res) => {
      const { data, errors, loading } = res;

      return {
        errors,
        loading,
        data: data?.[resultKey],
      };
    });
};

export const mutate = async (mutation, variables, resultKey, options) => {
  // @ts-ignore
  const { data, errors, loading } = await apolloClient
    .mutate({
      mutation,
      variables,
      ...options,
    })
    .catch((err) => ({ errors: err }));

  return {
    errors,
    loading,
    data: data && data[resultKey],
  };
};
