import useWizardPager, { Page } from '@/components/WizardPager/useWizardPager';
import {
  useRouter,
  reactive,
  ref,
  watch,
  computed,
} from '@nuxtjs/composition-api';
import UsStates from '@/constants/UsStates';
import {
  useFindOnboardingQuery,
  useUpsertEmployerOnboardingMutation,
  OnboardingStatus,
  CoreOnboardingDataInput,
  DiscussionTopic,
  CoreOnboardingData,
  NextOnboardingAction,
  LeadReason,
} from '@/gql/generated';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';

import useCurrentUser from '@/hooks/useCurrentUser/useCurrentUser';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import omitDeep from '@/utils/omitDeep';
import cloneDeep from 'lodash/cloneDeep';
import omit from 'lodash/omit';
import debounce from 'lodash/debounce';
import { saveInLocalStorage } from '@/utils/localStorage';
import {
  Step,
  pathDefaults,
  journeys,
} from '@/modules/Onboarding/consts/employerOnboardingPath';
import bam from '@/lib/bam';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';

const { currentUserId } = useCurrentUser();
// Version changes should coincide with the version changes in the API OR AB testing updates for flows and UIUX
const VERSION = Number(process.env.ER_ONBOARDING_WIZARD_VERSION);
const BASE_URL = '/onboarding/employer';

const redirectToPath = '/customizing';
export const callScheduledKey = `introHrmCallScheduled`;

let router;
let trackEvent;

let init;

let onboardingCompleted;
export const isOnboardingCompleted = () => onboardingCompleted;

const isReady = ref(false);

export enum Events {
  PAGE = 'EMPLOYER_ONBOARDING_PAGE',
  ENTERED_ONBOARDING = 'EMPLOYER_ONBOARDING_ENTERED_WIZARD',
  COMPLETED_ONBOARDING = 'EMPLOYER_ONBOARDING_COMPLETED',
  EXITED_ONBOARDING = 'EMPLOYER_ONBOARDING_EXITED_WIZARD',

  SHOWN_HRM_OPTION = 'EMPLOYER_ONBOARDING_SHOWN_HRM_OPTION',
  NOT_SHOWN_HRM_OPTION = 'EMPLOYER_ONBOARDING_NOT_SHOWN_HRM_OPTION',
  VIEWED_HRM_PAGE = 'EMPLOYER_ONBOARDING_VIEWED_HRM_PAGE',
  VIEWED_BM_PAGE = 'EMPLOYER_ONBOARDING_VIEWED_BM_PAGE',
  SCHEDULED_HRM_CALL = 'EMPLOYER_ONBOARDING_SCHEDULED_HRM_CALL',
  SCHEDULED_BM_CALL = 'EMPLOYER_ONBOARDING_SCHEDULED_BM_CALL',
}

export enum Topics {
  ACTIVE_HR_ISSUE = 'ACTIVE_HR_ISSUE',
  HR_POLICY = 'HR_POLICY',
  HR_COMPLIANCE = 'HR_COMPLIANCE',
  NEW_BUSINESS = 'NEW_BUSINESS',
  MANAGING_EMPLOYEES = 'MANAGING_EMPLOYEES',
  EXPANDING_BUSINESS = 'EXPANDING_BUSINESS',
  SOMETHING_ELSE = 'SOMETHING_ELSE',
}

const formModel: CoreOnboardingDataInput = reactive({
  discussionTopics: [],
  companyName: '',
  companyLegalName: '',
  companyType: null,
  fein: '',
  noFeinOrDoesNotKnow: false,
  address: {
    line1: '',
    line2: '',
    city: '',
    state: '',
    zipCode: '',
  },
  incorporatedInOtherState: false,
  incorporatedState: null,
  isOwner: true,
  primaryContact: true,
  willHaveAdditionalContact: false,
  numOfEmployees: null,
  numOfContractors: null,
  needsHelpConvertingContractors: false,
  hasRemoteEmployees: false,
  remoteWorkerStates: [],
  nextAction: null,
  callEventId: '',
  discussionTopicDetails: [],
  progress: 0,
});

const { company, companyId } = useCurrentCompany();

watch(
  company,
  (res) => {
    if (res && res.name && !formModel.companyLegalName) {
      formModel.companyLegalName = res.name;
    }
  },
  { immediate: true }
);

const stateOptions = Object.entries(UsStates).map(([key, value]) => ({
  label: value,
  value: key,
}));

const detailPageModel = ref('');
const firstActionModel = ref();

const getDiscussionTopicPages = (
  topics: DiscussionTopic[],
  startAtOrder: number
) => {
  let pages = {};
  topics.forEach((topic, index) => {
    pages = {
      ...pages,
      [`${Step.REQUEST_DETAILS}-${topic}`]: {
        slug: `request-details/${topic}`,
        id: `${Step.REQUEST_DETAILS}-${topic}`,
        label: 'Request Details',
        progressOrder: startAtOrder + index,
        onClick: (next) => {
          if (detailPageModel.value) {
            formModel.discussionTopicDetails =
              formModel?.discussionTopicDetails?.filter(
                (d) => d?.topic !== topic
              );

            formModel?.discussionTopicDetails?.push({
              topic: topic as DiscussionTopic,
              detail: detailPageModel.value,
            });
            detailPageModel.value = '';
          }
          next();
        },
      },
    };
  });

  return pages;
};

const canUserScheduleWithHrm = (form: CoreOnboardingDataInput): boolean => {
  // Business logic SOT: https://docs.google.com/spreadsheets/d/1ii8qkZrjnMAW0gpapuc3lA-uwmIrrtHOA6TCwbfuW24/edit#gid=1517569383
  // -- Check #1 : Company Info -- //
  const unknownFein = form.noFeinOrDoesNotKnow;
  const missingFein = !form.fein;
  const missingState = !form?.address?.state;

  if (unknownFein || missingFein || missingState) {
    return false;
  }

  // -- Check #2 : User Info -- //
  const { isOwner, primaryContact, willHaveAdditionalContact } = form;

  if (
    (isOwner && !primaryContact) ||
    (!isOwner && primaryContact && willHaveAdditionalContact) ||
    (!isOwner && !primaryContact && willHaveAdditionalContact)
  ) {
    return false;
  }

  // -- Check #3 : Employee Info -- //
  const hasEmployeeCount = !!form.numOfEmployees;
  const wantsToConvertContractors = form.needsHelpConvertingContractors;

  if (!hasEmployeeCount && !wantsToConvertContractors) {
    return false;
  }

  return true;
};

const wouldQualifyToShowHrmOption = computed(() =>
  canUserScheduleWithHrm(formModel)
);
// Previously canUserScheduleWithHrm(formModel)
// Temp setting for testing

const pages = computed<{ [key: string]: Page }>(() => {
  // Get the highest progress number from the defaults + 1 to start the dynamic pages
  const startAt =
    Object.values(pathDefaults).sort((i) => -i.progressOrder)[0].progressOrder +
    1;
  const detailPages = getDiscussionTopicPages(
    formModel.discussionTopics as DiscussionTopic[],
    startAt
  );

  return {
    ...pathDefaults,
    [Step.SELECT_FIRST_ACTION]: {
      ...pathDefaults[Step.SELECT_FIRST_ACTION],
      onClick: (next) => {
        // Delayed application to the model to make sure we don't think the form is complete when they haven't clicked 'next' and change their mind
        formModel.nextAction = firstActionModel.value;

        // If the user has selected to explore on their own, we won't have them schedule a call
        if (formModel.nextAction === NextOnboardingAction.ExploreOnYourOwn) {
          onboardingCompleted = true;
          router?.push(redirectToPath);

          return;
        }

        next();
      },
    },
    [Step.SCHEDULE_CALL]: {
      ...pathDefaults[Step.SCHEDULE_CALL],
      hideFooter: !formModel.callEventId,
      hideBack: !!formModel.callEventId,
      onClick: (next) => {
        // If the user has selected to talk with a BM, we won't ask them for the topic details
        if (
          formModel.nextAction === NextOnboardingAction.ScheduleConsultation
        ) {
          onboardingCompleted = true;
          router?.push(redirectToPath);

          return;
        }

        next();
      },
    },
    ...(detailPages || {}),
  };
});

const { isBambeeLite } = usePlanAccess();
const journey = computed(() =>
  isBambeeLite.value ? journeys.ER.LITE : journeys.ER.DEDICATED
);
const path = computed<Page[]>(() =>
  Object.values(pages.value).filter((p) =>
    journey.value.some((v) => p.id.includes(v))
  )
);

const hasPopulatedForm = ref(false);
const formStatus = ref();
const formId = ref();

let hasSentCompletedEvent = false;

const completeForm = () => {
  formStatus.value = OnboardingStatus.Completed;
  onboardingCompleted = true;
  trackEvent?.(Events.COMPLETED_ONBOARDING);
  hasSentCompletedEvent = true;
};

const saveForm = (form = {} as CoreOnboardingData, init = false) => {
  const { mutate, onDone } = useApolloMutation(
    useUpsertEmployerOnboardingMutation
  );

  onDone(({ upsertOnboarding: res }) => {
    formStatus.value = res.status;
    formId.value = res.id;
    isReady.value = true;
  });

  if (init) {
    formStatus.value = OnboardingStatus.Active;
  }

  const mappedFormData = {
    ...form,
    address: {
      // @ts-ignore -- inconsistent typing between client and api on address prop names
      zipCode: form?.address?.zip,
      ...omit(form.address, 'zip'),
    },
  };

  if (
    formModel.callEventId &&
    formModel.nextAction === NextOnboardingAction.MeetHrManager
  ) {
    saveInLocalStorage(callScheduledKey, true);
  }

  // We consider a form that has an event schedule as completed
  // If the user has selected to explore on their own, we also consider the form completed
  if (
    (formModel.callEventId ||
      formModel.nextAction === NextOnboardingAction.ExploreOnYourOwn) &&
    !hasSentCompletedEvent
  ) {
    completeForm();
  }

  mutate({
    data: {
      version: VERSION,
      data: init ? {} : mappedFormData,
      status: formStatus.value ?? undefined,
      id: formId.value ?? undefined,
      userId: currentUserId.value,
    },
  });
};

const mapReason = (reason: LeadReason): DiscussionTopic => {
  switch (reason) {
    case LeadReason.ActiveHrIssue:
      return DiscussionTopic.ManagingAnActiveHrIssue;
    case LeadReason.CompliancePolicies:
      return DiscussionTopic.CreatingCompanyPolicies;
    case LeadReason.EeOnboardingTraining:
    case LeadReason.PerformanceManagement:
    case LeadReason.Terminations:
      return DiscussionTopic.ManagingEmployees;
    case LeadReason.General:
    case LeadReason.Other:
      return DiscussionTopic.SomethingElse;
    case LeadReason.RecruitingHiring:
      return DiscussionTopic.ExpandingMyBusiness;
    case LeadReason.Wages:
      return DiscussionTopic.EnsuringMyBusinessIsCompliant;
    default:
      return DiscussionTopic.SomethingElse;
  }
};

const setupFormData = () => {
  // Initial form setup
  const { onResult } = useApolloQuery(useFindOnboardingQuery, {
    data: {
      userId: currentUserId.value,
      version: VERSION,
    },
    id: companyId.value,
  });

  onResult(({ findOnboarding: res, coreCompany }) => {
    const data = cloneDeep(res?.data) as CoreOnboardingData;

    if (res) {
      formStatus.value = res.status;
      formId.value = res.id;

      if (res.version === VERSION) {
        Object.entries(omitDeep(data, ['__typename'])).forEach(
          ([key, value]) => {
            if (value !== null) {
              formModel[key] = value;
            }
          }
        );

        // Make sure we're setting the company name if we have one and the model doesn't
        if (!formModel.companyLegalName && company.value?.name) {
          formModel.companyLegalName = company.value.name;
        }
        isReady.value = true;
      } else {
        // Handle new versions / migrations here in the future
      }
    } else {
      const reason = coreCompany.whatBroughtYouToBambee;
      if (!formModel.discussionTopics?.length && !!reason) {
        formModel.discussionTopics = [mapReason(reason)];
      }
      saveForm(
        {
          discussionTopics: formModel.discussionTopics,
        } as CoreOnboardingData,
        true
      );
      trackEvent?.(Events.ENTERED_ONBOARDING);
    }
  });
};

const handleModelUpdates = debounce((form) => {
  if (!hasPopulatedForm.value) {
    hasPopulatedForm.value = true;

    return;
  }

  saveForm(form);
}, 2000);

const updateModel = (
  updatedPartial: Partial<CoreOnboardingDataInput>,
  shouldSave = false
) => {
  Object.assign(formModel, updatedPartial);

  if (shouldSave) {
    saveForm(formModel as CoreOnboardingData);
  }
};

const hasSelectedHrm = computed(
  () => formModel.nextAction === NextOnboardingAction.MeetHrManager
);

const hasSelectedBm = computed(
  () => formModel.nextAction === NextOnboardingAction.ScheduleConsultation
);

const useEmployerOnboarding = () => {
  router = useRouter();

  const {
    setUserPath,
    setCurrentStep,
    onComplete,
    onPageChange,
    isNextDisabled,
    isLoadingNext,
    currentStep,
  } = useWizardPager(BASE_URL);

  trackEvent = (event: Events, data?: Object) => {
    bam.trackEvent(event, {
      version: VERSION,
      status: formStatus.value,
      isTestOnboarding: true,
      wasGivenHrmOption: true,
      wouldQualifyToShowHrmOption: wouldQualifyToShowHrmOption.value,
      currentPage: currentStep.value?.id,
      currentPageNum: currentStep.value?.progressOrder,
      allPages: path.value.map((p) => p.id),
      totalPages: path.value.length,
      form: formModel,
      ...data,
    });
  };

  if (!init) {
    watch(
      path,
      (p) => {
        setUserPath(p);
      },
      { immediate: true }
    );

    setCurrentStep(path.value[0].slug);

    onComplete(() => {
      completeForm();
      saveForm(formModel as CoreOnboardingData);

      trackEvent?.(Events.EXITED_ONBOARDING);
      router.push('/customizing');
    });

    const resetPageState = () => {
      isNextDisabled.value = false;
      isLoadingNext.value = false;
    };

    onPageChange((newPage) => {
      // We want to prevent users from navigating back to before the schedule page if they've scheduled a call already
      // Doing this here in lieu of in a routeguard to prevent unnecessary API calls
      if (
        newPage &&
        newPage.progressOrder < pages.value[Step.SCHEDULE_CALL].progressOrder &&
        !!formModel.callEventId
      ) {
        router.push(`${BASE_URL}/${pages.value[Step.SCHEDULE_CALL].slug}`);

        return;
      }

      resetPageState();
      if (newPage && newPage.progressOrder > (formModel.progress as number)) {
        updateModel({ progress: newPage.progressOrder });
      }

      if (newPage.progressOrder !== 1) {
        saveForm(formModel as CoreOnboardingData);
      }

      trackEvent?.(Events.PAGE);
    });

    // This form will automatically save the form data to the API when the formModel is updated
    // It uses a 2 second debounce to prevent excessive API calls
    watch(formModel, handleModelUpdates, { deep: true });

    setupFormData();
    init = true;
  }

  return {
    isReady,
    formModel,
    updateModel,
    hasSelectedHrm,
    hasSelectedBm,
    detailPageModel,
    stateOptions,
    trackEvent,
    firstActionModel,
  };
};

export default useEmployerOnboarding;
