
















































































import {
  TypeBody,
  AvatarImage,
  ModalDialog,
  BaseButton,
} from '@bambeehr/pollen';
import useCurrentUser from '@/hooks/useCurrentUser';
import {
  ref,
  defineComponent,
  computed,
  useStore,
} from '@nuxtjs/composition-api';
import { generateCalendlyURL } from '@/utils/calendly-helpers';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import bam from '@/lib/bam';
import { Milestones } from '@/modules/TaskCenter/types/Milestones';
import useTierZeroTasks from '@/modules/TaskCenter/hooks/useTierZeroTasks/useTierZeroTasks';
import ScheduleOnboardingCallConfirmation from '@/modules/TaskCenter/components/TierZeroGroup/ScheduleOnboardingCallConfirmation.vue';
import { getCookie, setCookie } from '@/lib/cookie-helpers';
import CookieNames from '@/constants/CookieNames';
import { useBusinessManagerScheduler } from '../../hooks';

const CalendlyCard = () => import('@/components/scheduler/CalendlyCard');

export const getCalendlyEventIdFromUri = (uri: string) => {
  return uri.split('/').pop();
};

export default defineComponent({
  // this component is used for tier zero users to schedule a call with their "onboarding coordinator"
  // the onboarding coordinator is the primary advisor for the company
  name: 'ScheduleOnboardingCall',
  components: {
    BaseButton,
    TypeBody,
    AvatarImage,
    CalendlyCard,
    ModalDialog,
    ScheduleOnboardingCallConfirmation,
  },
  setup() {
    const showCalendlyModal = ref(false);
    const { fetchMilestones, isWelcomeCallMilestoneComplete } =
      useTierZeroTasks();
    const { currentUser } = useCurrentUser();
    const { company } = useCurrentCompany();

    const onboardingMeetingId = ref(
      getCookie(CookieNames.NEW_CUSTOMER_MEETING_ID)
    );

    const store = useStore();
    const { bmCalendlyUrl } = useBusinessManagerScheduler(store, 0);

    const calendlyUrl = computed(() => {
      // use a team URL from calendly that will assign the call to the next available advisor
      if (bmCalendlyUrl.value) {
        return generateCalendlyURL({
          answers: ['', `1${currentUser.value?.profile?.phone}`],
          guest: currentUser.value,
          eventLink: {
            link: bmCalendlyUrl.value,
          },
        });
      }

      return undefined;
    });

    const handleScheduled = async (data) => {
      showCalendlyModal.value = false;
      bam.track('call-scheduled', {
        description: 'Tier Zero - Scheduled Onboarding Call',
      });

      const meetingUri = data.payload.event.uri || '';

      if (meetingUri) {
        onboardingMeetingId.value = getCalendlyEventIdFromUri(meetingUri) || '';
        setCookie(CookieNames.NEW_CUSTOMER_MEETING_ID, onboardingMeetingId);
      }

      await store.dispatch('companies/updateOnboardingMilestone', {
        milestone: Milestones.WELCOME_CALL,
        completed: true,
      });

      await store.dispatch('companies/updateOnboardingMilestone', {
        milestone: Milestones.ACKNOWLEDGE_HRM,
        completed: true,
      });

      fetchMilestones();
    };

    return {
      calendlyUrl,
      showCalendlyModal,
      currentUser,
      isWelcomeCallMilestoneComplete,
      onboardingMeetingId,
      handleScheduled,
    };
  },
});
