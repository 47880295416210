import {
  Exact,
  GetBillingInfoInput,
  GetBillingInfoPaymentMethodsQuery,
  useGetBillingInfoPaymentMethodsQuery,
} from '@/gql/generated';
import useCurrentCompany from '@/hooks/useCurrentCompany';

import { UseQueryReturn } from '@vue/apollo-composable';

let cachedUseQuery: UseQueryReturn<
  GetBillingInfoPaymentMethodsQuery,
  Exact<{ input: GetBillingInfoInput }>
> | null = null;

export default function usePaymentMethods() {
  const { companyId } = useCurrentCompany();
  const useQuery =
    cachedUseQuery ||
    useGetBillingInfoPaymentMethodsQuery({
      input: {
        companyId: companyId.value,
      },
    });

  if (!cachedUseQuery) {
    cachedUseQuery = useQuery;
  }

  return useQuery;
}
