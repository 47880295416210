/* eslint-disable @typescript-eslint/no-shadow */
import { CompanyStatuses } from '@/constants/companies';
import { UserRoles } from '@/constants/users';
import Vue from 'vue';

export const state = () => ({
  data: {},
  billingInfo: {},
  cancellationItems: {},
  onboardingMilestones: {},
  onboardingTasks: {},
});

export const mutations = {
  setCompanies(state, companies) {
    state.data = companies.reduce(
      (obj, company) => ({
        ...obj,
        [company._id]: company,
      }),
      state.data
    );
  },
  reset(state) {
    state.data = {};
    state.billingInfo = {};
    state.cancellationItems = {};
  },
  setCancellationItems(state, items) {
    state.cancellationItems = items;
  },
  setBillingInfo(state, { companyId, info }) {
    state.billingInfo = {
      ...state.billingInfo,
      [companyId]: info,
    };
  },

  setOnboardingMilestones(state, { companyId, milestones }) {
    Vue.set(state.onboardingMilestones, companyId, milestones);
  },

  setOnboardingMilestone(state, { companyId, milestone }) {
    const index = state.onboardingMilestones[companyId].findIndex(
      (m) => m.milestone === milestone.milestone
    );

    Vue.set(state.onboardingMilestones[companyId], index, milestone);
  },

  setOnboardingTasks(state, { companyId, tasks }) {
    Vue.set(state.onboardingTasks, companyId, tasks);
  },

  setOnboardingTask(state, { companyId, task }) {
    const index = state.onboardingTasks[companyId].findIndex(
      (t) => t.type === task.type
    );

    Vue.set(state.onboardingTasks[companyId], index, task);
  },
};

export const actions = {
  async getMyCompanies({ commit }) {
    const { data } = await this.$axios.get(`/v0/app/my/companies`, {
      params: { all: true },
    });
    commit('reset');
    commit('setCompanies', data);

    return data;
  },
  async getCancellationItems({ commit }) {
    const { data } = await this.$axios.get(
      `/v0/app/my/company-cancellation-information`
    );
    commit('setCancellationItems', data);

    return data;
  },

  reservePayrollSlot(_, companyId) {
    return this.$axios.post(
      `/company/companies/v2/${companyId}/payroll-reservation`
    );
  },

  signPayrollTOS(_, companyId) {
    return this.$axios.post(
      `/v0/companies/v1/company/${companyId}/payroll-tos-signed`,
      null
    );
  },

  async getOnboardingMilestones({ commit, rootGetters }) {
    const { companyId, currentUser } = rootGetters;
    const isAllowed = [UserRoles.ADMIN, UserRoles.USER].includes(
      currentUser.role
    );
    if (isAllowed) {
      try {
        const res = await this.$axios.get(
          `/company/companies/v2/${companyId}/onboarding`
        );

        if (!res) {
          return;
        }

        commit('setOnboardingMilestones', {
          companyId,
          milestones: res.data.onboardingMilestones,
        });
      } catch (e) {
        window.DD_RUM?.addError(e);
      }
    }
  },

  async updateOnboardingMilestone(
    { commit, rootGetters },
    { milestone, completed }
  ) {
    const { companyId } = rootGetters;

    try {
      const res = await this.$axios.put(
        `/company/companies/v2/${companyId}/onboarding`,
        {
          milestone,
          completed,
        }
      );

      commit('setOnboardingMilestone', {
        companyId,
        milestone: res.data,
      });
    } catch (e) {
      window.DD_RUM?.addError(e);
    }
  },

  async getOnboardingTasks({ commit, rootGetters }) {
    const { companyId, currentUser } = rootGetters;
    const isAllowed = [UserRoles.ADMIN, UserRoles.USER].includes(
      currentUser.role
    );
    if (isAllowed) {
      try {
        const res = await this.$axios.get(
          `/company/company/${companyId}/tasks`
        );

        if (!res) {
          return;
        }

        commit('setOnboardingTasks', {
          companyId,
          onboardingTasks: res.data,
        });

        // eslint-disable-next-line consistent-return
        return res.data;
      } catch (e) {
        window.DD_RUM?.addError(e);
      }
    }
  },

  async completeOnboardingTask({ commit, rootGetters }, { type }) {
    const { companyId } = rootGetters;

    try {
      // This put request will return the whole array of tasks given when a task is updated there is a potential that the parent goal will get completed as well.
      const res = await this.$axios.put(`/company/company/${companyId}/tasks`, {
        type,
      });

      commit('setOnboardingTasks', {
        companyId,
        onboardingTasks: res.data,
      });
    } catch (e) {
      window.DD_RUM?.addError(e);
    }
  },
};

export const getters = {
  active: (state) =>
    Object.values(state.data).filter(
      (company) => company.status !== CompanyStatuses.INACTIVE
    ),
  all: (state) => Object.values(state.data),
  byId: (state) => (id) => state.data[id],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  billingInfo: (state, getters, rootState, rootGetters) => {
    const { companyId } = rootGetters;

    return state.billingInfo[companyId];
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onboardingMilestones: (state, getters, rootState, rootGetters) => {
    const { companyId } = rootGetters;

    return state.onboardingMilestones[companyId];
  },
  onboardingMilestonesByTag:
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state, getters) => (tag) =>
      getters.onboardingMilestones?.filter((milestone) =>
        milestone.tags?.includes(tag)
      ),
  onboardingMilestonesByTagV2:
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (state, getters) => (tag) =>
      (getters.onboardingMilestones || []).filter((milestone) =>
        milestone.tags?.includes(tag)
      ),
  onboardingTasks: (state, getters, rootState, rootGetters) => {
    const { companyId } = rootGetters;

    return state.onboardingTasks[companyId];
  },
  onboardingTaskByGoals: (state, getters, rootState, rootGetters) => {
    const { companyId } = rootGetters;

    // Goals the parentId is null
    return state.onboardingTasks[companyId]?.filter((task) => !task.parentId);
  },
  onboardingTaskByType: (state, getters, rootState, rootGetters) => (type) => {
    const { companyId } = rootGetters;

    return state.onboardingTasks[companyId].find((task) => task.type === type);
  },
};
