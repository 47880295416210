import {
  APPROVE_PAYROLL,
  BULK_UPLOAD_TIME_ATTENDANCE,
  CREATE_COMPANY_CHECK,
  CREATE_PAYROLL,
  CREATE_PAY_SCHEDULE,
  CREATE_WORKPLACE,
  DELETE_PAYROLL,
  GENERATE_COMPANY_ONBOARDING_LINK,
  GENERATE_CONTRACTOR_ONBOARDING_LINK,
  GENERATE_EMPLOYEE_ONBOARDING_LINK,
  INITIALIZE_PAYROLL,
  PREVIEW_PAYROLL,
  SEND_STAFF_ONBOARDING_INVITE,
  SETUP_CONTRACTOR,
  SETUP_EMPLOYEE,
  UPDATE_CHECKS,
  UPDATE_COMPANY,
  UPDATE_CONTRACTOR_PAYMENT,
  UPDATE_CONTRACTOR_WORKPLACE,
  UPDATE_EMPLOYEE_WORKPLACE,
  UPDATE_PAYROLL,
  UPDATE_PAYROLL_ITEM,
  UPDATE_WORKPLACE,
  UPGRADE_FINCH_CODE,
  TRACK,
} from '@/gql/queries/payroll_mutations.gql';
import {
  COMPANY_PAYROLL_COUNT,
  GET_COMPANY,
  GET_COMPANY_STAFF,
  GET_COMPANY_STATUS,
  GET_CONTRACTOR_PAYMENTS,
  GET_CONTRACTOR_STATUS,
  GET_CONTRACTOR_TAX_DOCUMENTS,
  GET_DEFAULT_PAY_SCHEDULE_DAYS,
  GET_EMPLOYEE,
  GET_EMPLOYEE_PAYROLL_ITEMS,
  GET_EMPLOYEE_STATUS,
  GET_EMPLOYEE_TAX_DOCUMENTS,
  GET_PAYROLL,
  GET_TAX_DOCUMENTS,
  LIST_PAYROLL,
  LIST_PAYROLL_COUNT,
  LIST_WORKPLACE,
  GET_BAMBEE_PLUS_INFO,
  GET_SUBSCRIPTION_INFO,
  IS_EVENT_SCHEDULED_FOR_USER,
  GET_EMPLOYER_ONBOARDING_STATUS,
} from '@/gql/queries/payroll_queries.gql';
import { query, watchQuery, mutate } from '@/gql/apolloClient';

/**
 * Get a company's payroll status
 * @param {String} id company ID to query
 * @returns {Promise} Resulting company object with status and id
 */
export const getCompanyStatus = (id, options = {}) =>
  query(GET_COMPANY_STATUS, { id }, 'getCompany', options);

/**
 * Get a company's event scehduled status
 * @param {Object} data containing email and eventSlug
 * @returns {Promise} Resulting company object with email, eventSlug and isScheduled boolean
 */
export const getEventScheduledStatus = (data, options = {}) =>
  query(IS_EVENT_SCHEDULED_FOR_USER, data, 'isEventScheduledForUser', options);

/**
 * Get a company's staff list
 * @param {String} id company ID to query
 * @returns {Promise} Resulting company object with employee and contractor lists
 */
export const getCompanyStaff = (id) =>
  watchQuery(GET_COMPANY_STAFF, { id }, 'getCompany');

/**
 * Get a company's onboarding status benchmark status
 * @param {String} id company ID to query
 * @returns {Promise} Resulting company object with status and id
 */
export const getCompanyOnboardingStatus = (id) =>
  query(GET_COMPANY, { id }, 'getCompany');

/**
 * Get a single employee's payroll status
 * @param {String} id Employee ID to query
 * @returns {Promise} Resulting employee object with remaining steps, active status and id
 */
export const getEmployeeStatus = (id, options = {}) =>
  query(GET_EMPLOYEE_STATUS, { id }, 'getEmployee', options);

/**
 * Get a single contractor's payroll status
 * @param {String} id Contractor ID to query
 * @returns {Promise} Resulting employee object with remaining steps, active status and id
 */
export const getContractorStatus = (id) =>
  query(GET_CONTRACTOR_STATUS, { id }, 'getContractor');

/**
 * Get a company's details
 * @param {String} id company ID to query
 * @returns {Observable} Resulting payroll
 */
export const getCompany = (id) => watchQuery(GET_COMPANY, { id }, 'getCompany');

/**
 * Get a single employee
 * @param {String} id Employee ID to query
 * @returns {Promise} Resulting employee
 */
export const getEmployee = (id) =>
  watchQuery(GET_EMPLOYEE, { id }, 'getEmployee');

/**
 * Get a single employee's Tax Documents
 * @param {String} id Employee ID to query
 * @returns {Promise} Resulting employee with taxDocuments array
 */
export const getEmployeeTaxDocuments = (id) =>
  watchQuery(GET_EMPLOYEE_TAX_DOCUMENTS, { id }, 'getEmployee');

/**
 * Gets an employee's payroll items
 * @param {String} id Employee ID
 * @returns {Observable} Resulting Employee with Payroll Items
 */
export const getEmployeeWithPayrollItems = (id) =>
  watchQuery(GET_EMPLOYEE_PAYROLL_ITEMS, { id }, 'getEmployee');

/**
 * Get a single Contractor's tax documents
 * @param {String} id Contractor ID to query
 * @returns {Promise} Resulting contractor with taxDocuments array
 */
export const getContractorTaxDocuments = (id) =>
  watchQuery(GET_CONTRACTOR_TAX_DOCUMENTS, { id }, 'getContractor');

/**
 * Gets an contractor's payroll items
 * @param {String} id Contractor ID
 * @returns {Observable} Resulting Contractor with Payments
 */
export const getContractorWithPayments = (id) =>
  watchQuery(GET_CONTRACTOR_PAYMENTS, { id }, 'getContractor');

/**
 * Get a single payroll
 * @param {Number} id Payroll ID to query
 * @returns {Observable} Resulting payroll
 */
export const getPayroll = (id) =>
  watchQuery(
    GET_PAYROLL,
    {
      id,
    },
    'getPayroll'
  );

/**
 * Get the tax documents for a company by company ID
 * @param {Object} variables {companyId, ...filterOptions}
 * @returns {Observable} Contains the company ID and a list of tax documents
 */
export const watchTaxDocuments = ({ id, ...data }) =>
  watchQuery(
    GET_TAX_DOCUMENTS,
    {
      id,
      data,
    },
    'getCompany'
  );

/**
 * Get the status of the customers Bambee Plus subscription
 * @param {Object} data Containing companyId
 * @returns {Observable} Contains an object with the status of the subscription
 */
export const getBambeePlusInfo = (data) =>
  query(
    GET_BAMBEE_PLUS_INFO,
    {
      data,
      companyId: data.companyId,
    },
    ''
  );

/**
 * Get the list of workplaces for a company
 * @param {String} id Company ID to query for tax documents
 * @returns {Observable} Contains the list of all company workplaces
 */
export const watchWorkplaces = (companyId) =>
  watchQuery(LIST_WORKPLACE, { companyId }, 'listWorkplace');

/**
 * Get the list of workplaces for a company
 * @param {String} id Company ID to query for tax documents
 * @returns {Observable} Contains the list of all company workplaces
 */
export const listWorkplaces = (companyId) =>
  query(LIST_WORKPLACE, { companyId }, 'listWorkplace');

/**
 * Get the list of workplaces for a company
 * @param {String} id Company ID to query for tax documents
 * @returns {Observable} Contains the list of all company workplaces
 */
export const listPayrollCount = (companyId) =>
  query(LIST_PAYROLL_COUNT, { companyId }, 'listPayroll');

export const getEmployerOnboardingStatus = (data, options = {}) =>
  query(GET_EMPLOYER_ONBOARDING_STATUS, { data }, 'findOnboarding', options);

/**
 * Get the list of workplaces for a company
 * @param {String} id Company ID to query for tax documents
 * @returns {Observable} Contains the list of all company workplaces
 */
export const companyPayrollCount = (companyId) =>
  query(COMPANY_PAYROLL_COUNT, { companyId }, 'getCoreCompany');

/**
 * Gets an observable list of payrolls which can watch for updates
 * @param {Object} variables Query Variables to filter the request
 * @returns {Observable} Wraps a resulting list of payrolls
 */
export const watchPayrollList = (variables) =>
  watchQuery(LIST_PAYROLL, variables, 'listPayroll').map(
    ({ data, ...rest }) => {
      const { results } = data || {};

      return {
        ...rest,
        data: results,
      };
    }
  );

/**
 * Get full list of default payschedule days
 * @param {String} id Company ID to query for tax documents
 * @returns {Observable} Contains a list of the next 100 payschedule days
 */
export const getDefaultPayScheduleDays = (id) =>
  watchQuery(GET_DEFAULT_PAY_SCHEDULE_DAYS, { id }, 'getCompany');

/**
 * Get full list of default payschedule days
 * @param {String} id Company ID to query for tax documents
 * @returns {Observable} Contains a list of the next 100 payschedule days
 */
export const getCompanySubscriptions = (data) =>
  query(GET_SUBSCRIPTION_INFO, { data }, 'companySubscriptions');
//

/**
 * Approves a payroll by ID
 * @param {Object} data Object to match APPROVE_PAYROLL
 * @returns {Object} Payroll Object
 */
export const approvePayroll = async (data) =>
  mutate(APPROVE_PAYROLL, { data }, 'approvePayroll');

/**
 * Deletes a payroll by ID
 * @param {Object} data Object to match DELETE_PAYROLL
 * @param {String} companyId Company id for refetching list payroll
 * @returns {Object} Contains the payroll ID & now populated deletedAt timestamp
 */
export const deletePayroll = async ({ data, companyId }) =>
  mutate(DELETE_PAYROLL, { data }, 'deletePayroll', {
    refetchQueries: [
      {
        query: LIST_PAYROLL,
        variables: {
          companyId,
        },
      },
    ],
  });

/**
 * Updates checks and check status of a payroll by ID
 * @param {Object} data Object to match APPROVE_PAYROLL
 * @returns {Object} Payroll Object
 */
export const updateChecks = async (data) =>
  mutate(UPDATE_CHECKS, { data }, 'updatePayrollChecks');

/**
 * Updates workplace of given employee
 * @param {Object} data Object which includes workplaceId and employeeId
 * @returns {Object} Object to match Employee type
 */
export const updateEmployeeWorkplace = async (data) =>
  mutate(UPDATE_EMPLOYEE_WORKPLACE, { data }, 'updateEmployeeWorkplace');

/**
 * Updates workplace of given contractor
 * @param {Object} data Object which includes workplaceId and contractorId
 * @returns {Object} Object to match Employee type
 */
export const updateContractorWorkplace = async (data) =>
  mutate(UPDATE_CONTRACTOR_WORKPLACE, { data }, 'updateContractorWorkplace');

/**
 * Updates name, default or active status of current workplace
 * @param {Object} data Object to match APPROVE_PAYROLL
 * @returns {Object} Payroll Object
 */
export const updateWorkplace = async ({ data, companyId }) =>
  mutate(UPDATE_WORKPLACE, { data }, 'updateWorkplace', {
    refetchQueries: [
      {
        query: LIST_WORKPLACE,
        variables: {
          companyId,
        },
      },
    ],
  });

/**
 * Create new workplace
 * @param {Object} data Object to match APPROVE_PAYROLL
 * @returns {Object} Payroll Object
 */
export const createWorkplace = async (data) =>
  mutate(CREATE_WORKPLACE, { data }, 'createWorkplace', {
    refetchQueries: [
      {
        query: LIST_WORKPLACE,
        variables: {
          companyId: data.companyId,
        },
      },
    ],
  });

/**
 * Updates checks and check status of a payroll by ID
 * @param {Object} data Object to match APPROVE_PAYROLL
 * @returns {Object} Payroll Object
 */
export const createCompanyInCheck = async (data) =>
  mutate(CREATE_COMPANY_CHECK, { data }, 'createCompanyInCheck');

/**
 * Updates checks and check status of a payroll by ID
 * @param {Object} data Object to match UPDATE_PAYROLL_ITEM
 * @returns {Object} PayrollItem Object
 */
export const updatePayrollItem = async (data) =>
  mutate(UPDATE_PAYROLL_ITEM, { data }, 'updatePayrollItem');

/**
 * Updates contractor payment information
 * @param {Object} data Object to match UPDATE_CONTRACTOR_PAYMENT
 * @returns {Object} ContractorPayment Object
 */
export const updateContractorPayment = async (data) =>
  mutate(UPDATE_CONTRACTOR_PAYMENT, { data }, 'updateContractorPayment');

/**
 * Updates checks and check status of a payroll by ID
 * @param {Object} data Object to match UPDATE_PAYROLL_ITEM
 * @returns {Object} PayrollItem Object
 */
export const updatePayroll = async (data) =>
  mutate(UPDATE_PAYROLL, { data }, 'updatePayroll');

/**
 * Creates a new payroll
 * @param {Object} data Object to match CREATE_PAYROLL
 * @returns {Object} Created payroll
 */
export const createPayroll = async (data) =>
  mutate(CREATE_PAYROLL, { data }, 'createPayroll');

/**
 * Initializes a payroll
 * @param {Object} id Payroll ID to initialize
 * @returns {Object} Initialized Payroll ID
 */
export const initializePayroll = async (id) =>
  mutate(INITIALIZE_PAYROLL, { data: { id } }, 'initializePayroll');

/**
 * Sets a payroll to preview via Check
 * @param {Object} data Object to match PREVIEW_PAYROLL
 * @returns {Object} Created payroll
 */
export const previewPayroll = async (id) =>
  mutate(PREVIEW_PAYROLL, { id }, 'previewPayroll');

/**
 * Sets a company's pay schedule assistance flag or TOS version
 * @param {Object} data Object to match {id: string, needsPayScheduleAssistance: boolean, tosVersion: string}
 * @returns {Object} Company object
 */
export const updateCompany = async (data) =>
  mutate(UPDATE_COMPANY, { data }, 'updateCompany');

/**
 * Creates new pay schedule
 * @param {Object} data Object to match PaySchedule type
 * @returns {Object} Id of created paySchedule
 */
export const createPaySchedule = async (data) =>
  mutate(CREATE_PAY_SCHEDULE, { data }, 'createPaySchedule', {
    refetchQueries: [
      {
        query: GET_COMPANY,
        variables: {
          id: data.companyId,
        },
      },
    ],
  });

/**
 * Creates employee onboarding link for Check onboarding
 * @param {String} id Employee id
 * @returns {String} Onboarding link string
 */
export const generateEmployeeOnboardingLink = async (id) =>
  mutate(
    GENERATE_EMPLOYEE_ONBOARDING_LINK,
    { id },
    'generateEmployeeOnboardingLink'
  );

/**
 * Creates contractor onboarding link for Check onboarding
 * @param {String} id Contractor id
 * @returns {String} Onboarding link string
 */
export const generateContractorOnboardingLink = async (id) =>
  mutate(
    GENERATE_CONTRACTOR_ONBOARDING_LINK,
    { id },
    'generateContractorOnboardingLink'
  );

/**
 * Creates Employee onboarding link for Check onboarding
 * @param {String} id Employee id
 * @returns {String} Onboarding link string
 */
export const generateCompanyOnboardingLink = async (data) =>
  mutate(
    GENERATE_COMPANY_ONBOARDING_LINK,
    { data },
    'generateCompanyOnboardingLink'
  );

export const setupEmployee = async (data) =>
  mutate(SETUP_EMPLOYEE, { data }, 'setupEmployee');

export const setupContractor = async (data) =>
  mutate(SETUP_CONTRACTOR, { data }, 'setupContractor');

export const upgradeFinchCode = async (data) =>
  mutate(UPGRADE_FINCH_CODE, { data }, 'upgradeFinchCode');

export const sendStaffOnboardingInvite = async (data) =>
  mutate(SEND_STAFF_ONBOARDING_INVITE, { data }, 'sendStaffOnboardInvite');

export const bulkUploadTimeAttendance = async (data) =>
  mutate(BULK_UPLOAD_TIME_ATTENDANCE, { data }, 'bulkEarningsImport');

export const track = async (data) => mutate(TRACK, { data }, 'track');

export default null;
