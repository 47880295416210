import {
  AddressValidation,
  suggestAddresses,
  validateAddress,
} from '@/services/address.service';
import { getFullAddress } from '@/utils/address';
import { Address } from '@@/types/company';
import useNotifications from '@bambeehr/use-notifications';
import { computed, reactive, ref, watch } from '@nuxtjs/composition-api';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';
import shortId from 'shortid';

export interface Suggestion {
  label: string;
  address: Address;
  id: string;
  isActive: boolean;
}

export enum KeyCode {
  DOWN = 40,
  UP = 38,
  ENTER = 13,
}

export enum EventType {
  BLUR = 'blur',
  KEYDOWN = 'keydown',
}

export const emptyForm = {
  line1: '',
  line2: '',
  zip: '',
  city: '',
  state: '',
};

const { addInfo } = useNotifications();

const workingForm = reactive<Partial<Address>>(cloneDeep(emptyForm));
const suggestions = ref<Suggestion[]>([]);
const showSuggestions = ref(false);
const hasInvalidAddress = ref(false);
const addressCandidate = ref<AddressValidation | null>(null);
const isDisabled = ref<boolean>(false);

// For managing address state updates in UI, updating here: PAY-1808
const addressKey = ref(shortId.generate());

// Address state and validations
const formIsFull = computed<boolean>(
  () => !Object.values(omit(workingForm, ['line2'])).some((i) => !i)
);
const addressIsValid = computed<boolean>(
  () => formIsFull.value && !addressCandidate.value && !hasInvalidAddress.value
);
const canShowCandidateMessage = computed<boolean>(
  () =>
    !isDisabled.value && (!showSuggestions.value || !suggestions.value.length)
);

async function validate(address: Address) {
  if (isDisabled.value) {
    return;
  }

  addressCandidate.value = null;
  const res = await validateAddress(address);

  if (res?.isValid) {
    hasInvalidAddress.value = false;
    if (res.needsFormatting) {
      addressCandidate.value = res;
    }

    return;
  }

  hasInvalidAddress.value = true;
}

async function lookupAddress(address: string) {
  if (address?.length) {
    const res = await suggestAddresses(address, { maxResults: 6 });

    suggestions.value = res.map((suggestion: Address, index: number) => ({
      label: getFullAddress(suggestion),
      address: suggestion,
      id: shortId.generate(),
      isActive: !index,
    }));

    return;
  }

  suggestions.value = [];
}

// As the form is updated we need to make sure that the current address is actually valid
watch(
  <Address>workingForm,
  async (form: Address) => {
    const currentForm = cloneDeep(form);

    // Make sure that we at least have some value for line1 before validating
    // If we don't, the Smarty API will throw an error
    if (form.line1) {
      debounce(() => {
        if (isEqual(currentForm, workingForm)) {
          validate(form);
        }
      }, 500)();
    }
  },
  { immediate: true }
);

// Suggestion highlighted element
const currentSuggestion = computed<Suggestion>(() => {
  let activeSuggestion = suggestions.value.find((s) => s.isActive);

  if (!activeSuggestion) {
    [activeSuggestion] = suggestions.value;
    if (activeSuggestion) {
      activeSuggestion.isActive = true;
    }
  }

  return activeSuggestion || suggestions.value[0];
});
const currentSuggestionIndex = computed<number>(() =>
  suggestions.value.findIndex((s) => s.id === currentSuggestion.value?.id)
);

function toggleSuggestion(suggestion: Suggestion, isActive: boolean) {
  suggestions.value = suggestions.value.map((thisSuggestion) => ({
    ...thisSuggestion,
    isActive: suggestion.id === thisSuggestion.id ? isActive : false,
  }));
}

function updateAddressSuggestions(query: string) {
  const searchTerm = query;
  showSuggestions.value = true;

  debounce(
    () => {
      if (searchTerm === workingForm.line1) {
        lookupAddress(searchTerm);
      }
    },
    200,
    { maxWait: 1000 }
  )();
}

function moveActiveSuggestion(adjustIndex: number) {
  const currentIndex = currentSuggestionIndex.value;
  const currentItem = suggestions.value[currentIndex];
  const itemToSelect = suggestions.value[currentIndex + adjustIndex];

  if (currentItem && itemToSelect) {
    currentItem.isActive = false;
    itemToSelect.isActive = true;
  }
}

function hideSuggestions() {
  showSuggestions.value = false;
}

function selectSuggestion() {
  hasInvalidAddress.value = false;
  addressCandidate.value = null;
  Object.assign(workingForm, currentSuggestion.value?.address);
  hideSuggestions();
  // For managing UI state, updating here: PAY-1808
  addressKey.value = shortId.generate();
}

function handleAddressNav(event: KeyboardEvent) {
  switch (event.keyCode) {
    case KeyCode.DOWN:
      event.preventDefault();
      moveActiveSuggestion(1);
      break;

    case KeyCode.UP:
      event.preventDefault();
      moveActiveSuggestion(-1);
      break;

    case KeyCode.ENTER:
      event.preventDefault();
      selectSuggestion();
      break;

    default:
      break;
  }
}

function resetAddressForm() {
  Object.assign(workingForm, emptyForm);
  addressCandidate.value = null;
  addressKey.value = shortId.generate();
}

function applyCandidateAddress() {
  Object.assign(workingForm, addressCandidate.value?.validatedAddress);
  addInfo('Address updated.');
  addressCandidate.value = null;
  // For managing UI state, updating here: PAY-1808
  addressKey.value = shortId.generate();
}

function handleAddressEvent(event) {
  switch (event.type) {
    case EventType.KEYDOWN:
      handleAddressNav(event);
      break;

    default:
      break;
  }
}

const useAddressForm = () => ({
  addressCandidate,
  addressIsValid,
  addressKey,
  canShowCandidateMessage,
  currentSuggestion,
  currentSuggestionIndex,
  formIsFull,
  hasInvalidAddress,
  isDisabled,
  showSuggestions,
  suggestions,
  workingForm,

  applyCandidateAddress,
  handleAddressEvent,
  hideSuggestions,
  moveActiveSuggestion,
  resetAddressForm,
  selectSuggestion,
  toggleSuggestion,
  updateAddressSuggestions,
});

export default useAddressForm;
