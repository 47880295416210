import { useApolloMutation } from '@/gql/apolloWrapper';
import { PayType } from '@/lib/globals/users';

import {
  SetupContractorInput,
  SetupEmployeeInput,
  UpdateContractorWorkplaceInput,
  UpdateEmployeeWorkplaceInput,
  useAssignWorkerRolesMutation,
  useSetupContractorMutation,
  useSetupEmployeeMutation,
  useUpdateContractorWorkplaceMutation,
  useUpdateEmployeeWorkplaceMutation,
} from '@/gql/generated';
import { GET_FULL_STAFF } from '@/gql/queries/payroll_queries.gql';
import currency from '@bambeehr/currency';
import useNotifications from '@bambeehr/use-notifications';

const { addError } = useNotifications();

const getStateWorkedIn = (userInfo, workplaces) => {
  const { state, workplace } = userInfo;
  let stateWorkedIn = state;

  if (workplace && workplaces?.length) {
    stateWorkedIn = workplaces.find((w) => w.value === workplace)?.address
      ?.state;
  }

  return stateWorkedIn;
};

// API expects a very specific contract so we need to pick these out of the userInfo
const getCreatePayload = (userInfo, workplaces) => {
  const {
    beginOnboarding,
    email,
    firstName,
    isContractor,
    lastName,
    phoneNumber,
    startDate,
    role,
  } = userInfo;

  return {
    user: {
      beginOnboarding,
      email,
      firstName,
      isContractor,
      lastName,
      phoneNumber,
      startDate,
      state: getStateWorkedIn(userInfo, workplaces),
      role,
      // The following information is now managed by the workerRoles data
      // This will all be replaced when mutations for creating and updating users is unlocked
      payAmount: 0,
      payType: PayType.HOURLY,
      title: '',
    },
  };
};

const mapRoleToSaveFormat = (
  { payRate, isPrimary, id, companyRole, isNew, payType },
  userId
) => ({
  payRate: isNew ? currency(payRate).value : undefined,
  isPrimary,
  companyRoleId: isNew ? companyRole.id : undefined,
  userId: isNew ? userId : undefined,
  workerRoleId: isNew ? undefined : id,
  payType,
});

// Worker roles are never "updated" except for the isPrimary bool
// When the UI updates a role, it actually soft deletes it and creates a new role
// This fn handles the deleting and recreating of roles for the mutation
const normalizeWorkerRoles = (role) => {
  // Make updated roles additions
  if (role.isEdited && !role.isNew && !role.isDeleted) {
    return [
      {
        ...role,
        isDeleted: true,
        isEdited: false,
      },
      {
        ...role,
        id: '',
        isNew: true,
        isEdited: false,
      },
    ];
  }

  return role;
};

// API expects a very specific contract so we need to pick these out of the userInfo
const getUpdatePayload = (
  userId,
  userInfo,
  workplaces,
  isEmailUpdated: boolean = false
) => {
  const {
    address,
    dob,
    email,
    firstName,
    isContractor,
    lastName,
    payAmount,
    payType,
    phoneNumber,
    startDate,
    title,
    employeeType,
    contractorType,
    contractorBusinessName,
  } = userInfo;

  // If EMAIL is updated, add property "email" into base root level of payload
  const emailUpdatePayload = isEmailUpdated ? { email } : {};

  return {
    ...emailUpdatePayload,
    _id: userId,
    profile: {
      address: address.line1 || undefined,
      address2: address.line2 || undefined,
      city: address.city || undefined,
      state: address.state || undefined,
      zip: address.zip || undefined,
      dob: dob || undefined,
      email,
      first_name: firstName,
      contractor: isContractor,
      last_name: lastName,
      pay_rate: payAmount,
      type: payType,
      phone: phoneNumber,
      start_date: startDate,
      state_work_in: getStateWorkedIn(userInfo, workplaces),
      role: title,
      employee_type: employeeType || undefined,
      contractor_type: contractorType || undefined,
      contractor_business_name: isContractor
        ? contractorBusinessName
        : undefined,
    },
  };
};

const saveUserInfo = async (
  store,
  companyId,
  userInfo,
  workplaces,
  isEmailUpdated
): Promise<{ userId: string }> => {
  const {
    onboardToPayroll,
    isContractor,
    workplace,
    isPayrollUser,
    isTimeAndAttendanceUser,
  } = userInfo;
  let userId = userInfo.id;

  // No id = new user
  if (!userId) {
    try {
      const res = await store.dispatch(
        'users/createUser',
        getCreatePayload(userInfo, workplaces)
      );
      userId = res.userId;
    } catch (error) {
      // [Custom Error Message]: Catch error case with phoneNumber input
      if (error?.response?.data?.[0]?.path === '.phoneNumber') {
        addError('Enter a valid phone number');
        console.warn('Enter a valid phone number');
      }
      addError(error?.response?.data?.[0]?.message);
      throw new Error('Unable to create user');
    }
  }

  try {
    await store.dispatch(
      'users/updateUser',
      getUpdatePayload(userId, userInfo, workplaces, isEmailUpdated)
    );
  } catch (error) {
    // @ts-ignore
    addError(error?.response?.data?.message);
    console.warn('Unable to update user');
  }

  const workerRoles = userInfo.workerRoles.value
    .map(normalizeWorkerRoles)
    .flat();

  const newRoles = workerRoles
    .filter((r) => r.isNew && !r.isDeleted)
    .map((r) => mapRoleToSaveFormat(r, userId));
  const updatedRoles = workerRoles
    .filter((r) => r.isPrimary && !r.isNew && !r.isUpdated && !r.isDeleted)
    .map((r) => mapRoleToSaveFormat(r, userId));
  const deletedRoles = workerRoles
    .filter((r) => r.isDeleted && !r.isNew)
    .map((r) => r.id);

  const { mutate: assignRoles } = useApolloMutation(
    useAssignWorkerRolesMutation
  );

  assignRoles({
    data: {
      userId,
      add: newRoles,
      update: updatedRoles,
      delete: deletedRoles,
    },
  });

  if (onboardToPayroll) {
    const {
      mutate: setupEmployee,
      onDone: finishedSettingUpEmployee,
      onError: errorSettingUpEmployee,
    } = useApolloMutation(useSetupEmployeeMutation);
    const {
      mutate: setupContractor,
      onDone: finishedSettingUpContractor,
      onError: errorSettingUpContractor,
    } = useApolloMutation(useSetupContractorMutation);

    const [entityIdKey, setupStaffService, onServiceComplete, onServiceError] =
      isContractor
        ? [
            'contractorId',
            setupContractor,
            finishedSettingUpContractor,
            errorSettingUpContractor,
          ]
        : [
            'employeeId',
            setupEmployee,
            finishedSettingUpEmployee,
            errorSettingUpEmployee,
          ];

    setupStaffService(
      {
        data: {
          [entityIdKey]: userId,
          companyId,
          workplaceId: workplace,
        } as SetupEmployeeInput & SetupContractorInput,
      },
      {
        // Refetch the list of workers.
        // This is currently referenced on the worker pay page.
        refetchQueries: [
          {
            query: GET_FULL_STAFF,
            variables: {
              id: companyId,
            },
          },
        ],
      }
    );

    onServiceComplete(() => {
      return { userId };
    });

    onServiceError((error) => {
      throw new Error(error.networkError?.[0].message);
    });
  } else if (workplace && (isPayrollUser || isTimeAndAttendanceUser)) {
    const {
      mutate: updateEmployeeWorkplace,
      onDone: finishedUpdatingEmployeeWorkplace,
      onError: errorUpdatingEmployeeWorkplace,
    } = useApolloMutation(useUpdateEmployeeWorkplaceMutation);
    const {
      mutate: updateContractorWorkplace,
      onDone: finishedUpdatingContractorWorkplace,
      onError: errorUpdatingContractorWorkplace,
    } = useApolloMutation(useUpdateContractorWorkplaceMutation);

    const [entityIdKey, setupStaffService, onServiceComplete, onServiceError] =
      isContractor
        ? [
            'contractorId',
            updateContractorWorkplace,
            finishedUpdatingContractorWorkplace,
            errorUpdatingContractorWorkplace,
          ]
        : [
            'employeeId',
            updateEmployeeWorkplace,
            finishedUpdatingEmployeeWorkplace,
            errorUpdatingEmployeeWorkplace,
          ];

    setupStaffService({
      data: {
        [entityIdKey]: userId,
        workplaceId: workplace,
      } as UpdateEmployeeWorkplaceInput & UpdateContractorWorkplaceInput,
    });

    onServiceComplete(() => {
      return { userId };
    });

    onServiceError((error) => {
      throw new Error(error.networkError?.[0].message);
    });
  }

  return { userId };
};

export default saveUserInfo;
