import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';

import {
  useGetCompanyQuery,
  useGetUserRolesQuery,
  useListWorkplaceQuery,
  useUpdateCompanyMutation,
  WorkerRole,
} from '@/gql/generated';
import { computed, ref, watch } from '@nuxtjs/composition-api';

import { UserRoles } from '@/constants/users';
import { getPayTypeOptions, PayType } from '@/lib/globals/users';
import phone, { formats } from '@/lib/phone_validate';
import { formatWorkplaceAsOption } from '@/modules/payroll/utils/formatWorkplace';
import type { WorkplaceOption } from '@/modules/payroll/utils/formatWorkplace';
import isArray from 'lodash/isArray';
import saveUserInfo from '../utils/saveUserInfo';

import useAddressForm from '@/components/AddressForm/useAddressForm';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import usePayrollOnboardingStatus from '@/modules/OnboardingWizard/hooks/usePayrollOnboardingStatus';
import { UserPreference } from '@/modules/payroll/constants/payroll';
import useNotifications from '@bambeehr/use-notifications';
import useAddStaffFormRole from '../components/AddStaffFormRoles/useAddStaffFormRole';
import useAddStaffFormState from './useAddStaffFormState';
import type { AddStaffFormModel } from './useAddStaffFormState';

const labels = {
  line1: 'Home Address',
  line2: 'Home Address Line 2',
  city: 'City',
  state: 'State',
  zip: 'Zip',
};

const workerRoles = ref<WorkerRole[]>([]);
const rolesAreLoading = ref(false);

export const getAddressLabels = (needsPayrollInfo: boolean) =>
  Object.entries(labels).reduce((acc, [key, value]) => {
    acc[key] = `${value} ${needsPayrollInfo ? '' : ' (optional)'}`;

    return acc;
  }, {});

const { createRole, validate } = useAddStaffFormRole();
const { companyId } = useCurrentCompany();
const { addError, addSuccess } = useNotifications();
const { payrollCompany, excludedIdList } = usePayrollOnboardingStatus(true);
const { currentEmail, formState, v$, needsPayrollInfo } =
  useAddStaffFormState();
const {
  workingForm: addressForm,
  resetAddressForm,
  addressIsValid,
} = useAddressForm();

const hasPayroll = computed(() => !!payrollCompany.value?.checkId);
const hasTimeAndAttendance = computed(
  () => payrollCompany.value?.deputyConnection?.hasAccessToken
);

let emit;
let store;

const isEditing = ref(false);
const isPayrollUser = ref(false);
const showBeginOnboarding = ref(true);

const isSavingWorker = ref(false);
const workplaceOptions = ref<WorkplaceOption[]>([]);
const inviteAsAdmin = ref(false);
const isSimplifiedForm = ref(false);

const hasWorkplaces = computed(() => !!workplaceOptions.value.length);

export const setAddressHookState = (
  initialState,
  addForm,
  editing: boolean
) => {
  if (editing && initialState?.address) {
    const { address, address2, ...addressFormData } = initialState.address;

    if (address) {
      Object.assign(addForm, {
        line1: address,
        line2: address2,
        ...addressFormData,
      });
    }
  }
};

export const getPhoneNumberLabel = (isOptional: boolean) =>
  `Phone Number ${isOptional ? '(optional)' : ''}`;

export const getEmailLabel = (isOptional: boolean) =>
  `Email ${isOptional ? '(optional)' : ''}`;

export const getSaveBtnLabel = (editing: boolean) =>
  editing ? 'Update' : 'Add Worker';

watch(hasPayroll, (newVal) => {
  if (newVal && !isEditing.value && !isSimplifiedForm.value) {
    formState.onboardToPayroll = true;
  }
});

// Updating some form selections when related fields are changed
watch(
  [formState, workplaceOptions],
  ([form, workplaces]) => {
    // Typecasting because TS wasn't able to properly infer these types because of the variable array types
    const thisForm = form as AddStaffFormModel;
    const workplaceList = workplaces as unknown as {
      label: string;
      value: string;
      id: string;
    }[];

    if (!thisForm.isContractor && formState.payType === PayType.CONTRACT) {
      formState.payType = PayType.HOURLY;
    }

    if (
      thisForm.onboardToPayroll &&
      !thisForm.workplace &&
      workplaceList?.length === 1
    ) {
      formState.workplace = workplaceOptions.value[0]?.value;
    }
  },
  { deep: true }
);

// Handle Form Data Validation
const handleAddStaffFormValidation = (formData) => {
  if (formData.email?.$error) {
    addError('Enter a valid email');
  }
  if (formData.phoneNumber?.$error) {
    addError('Enter a valid phone number');
  }
};

const getWorkplaceOptions = () => {
  const unwatch = watch(
    companyId,
    (id) => {
      if (id) {
        const { onResult: onWorkplaceResult } = useApolloQuery(
          useListWorkplaceQuery,
          { companyId: id }
        );
        onWorkplaceResult(({ listWorkplace: res }) => {
          workplaceOptions.value = res.map((w) => formatWorkplaceAsOption(w));

          if (
            hasWorkplaces.value &&
            (!isEditing.value || !formState.workplace) &&
            workplaceOptions.value.length === 1
          ) {
            formState.workplace = res.find((w) => w.default)?.id || res[0]?.id;
          }
        });

        if (unwatch) {
          unwatch();
        }
      }
    },
    { immediate: true }
  );
};

const addToExcludedIdList = (id: string, currentList: string[]) => {
  const { mutate } = useApolloMutation(useUpdateCompanyMutation);

  mutate({
    data: {
      id: companyId.value as string,
      excludedStaffIds: [...currentList, id],
    },
  });
};

const workerId = computed(() => formState.id);

const handleSubmit = async () => {
  if (
    hasWorkplaces.value &&
    (!isEditing.value || !formState.workplace) &&
    workplaceOptions.value.length === 1
  ) {
    formState.workplace =
      workplaceOptions.value.find((w) => w.default)?.id ||
      workplaceOptions.value[0]?.id;
  }

  const isValid = await v$.value.$validate();
  const rolesAreValid = validate();
  /**
   * Check for email update to determine whether to add email
   * to the update payload or not.
   * TWO NON-EMAIL UPDATE CASES
   * 1. currentEmail.value === '' (no initial email state set)
   * 2. currentEmail.value === formState.email (email initialized but never changed)
   */
  const isEmailUpdated =
    currentEmail.value === '' ? false : currentEmail.value !== formState.email;
  // handle validation errors
  if (!isValid) {
    handleAddStaffFormValidation(v$.value);
    emit('error');

    return;
  }

  if (!rolesAreValid) {
    addError('Invalid employee roles');
    emit('error');

    return;
  }

  isSavingWorker.value = true;

  try {
    const payload = {
      ...formState,
      address: addressForm,
      phoneNumber: phone.format(formState.phoneNumber, formats.E164),
      payAmount: parseFloat(formState.payAmount),
      hasPayroll: hasPayroll.value,
      isPayrollUser: isPayrollUser.value,
      role: inviteAsAdmin.value ? UserRoles.USER : undefined,
      startDate: formState.startDate || '',
      workerRoles,
      isTimeAndAttendanceUser: hasTimeAndAttendance.value,
    };

    const res = await saveUserInfo(
      store,
      companyId.value,
      payload,
      workplaceOptions.value,
      isEmailUpdated
    );

    if (res.userId) {
      addSuccess(`Worker ${isEditing.value ? 'Updated' : 'Added'}!`);

      // Set to let the payroll know to pull fresh info as titles or pay rate may have changed.
      sessionStorage.setItem(UserPreference.UPDATED_WORKER_INFO, 'true');

      // If customer has payroll, but sets onboard to payroll to false, add them
      // to the excluded list so we don't set them initally to true in the future
      // We only do this if they're not using the simplified form as we don't onboarding to payroll in that form
      if (
        hasPayroll.value &&
        !payload.onboardToPayroll &&
        !isSimplifiedForm.value
      ) {
        addToExcludedIdList(res.userId, excludedIdList.value);
      }
      emit('success', {
        ...payload,
        id: res.userId,
      });
    }
  } catch (err: any) {
    console.error(err);
    emit('error');
    if (err?.response?.status === 400) {
      const result = err?.response?.data;
      if (isArray(result) && result.length) {
        addError(result[0].message);
      }
    }
  } finally {
    isSavingWorker.value = false;
  }
};

// Computed props
const phoneLabel = computed(() => getPhoneNumberLabel(!!formState.email));

const filteredPayTypes = computed(() =>
  getPayTypeOptions(formState.isContractor)
);

const workerIsContractor = computed(() => formState.isContractor);

const emailLabel = computed(() =>
  getEmailLabel(
    !!formState.phoneNumber && !formState.email && !formState.onboardToPayroll
  )
);

const hasMultipleWorkplaces = computed(() => workplaceOptions.value.length > 1);

const saveBtnLabel = computed(() => getSaveBtnLabel(isEditing.value));

const addressLabels = computed(() => getAddressLabels(needsPayrollInfo.value));

const resetAdminState = () => {
  inviteAsAdmin.value = false;
};

const getWorkerRoles = () => {
  const unwatch = watch(
    workerId,
    (id) => {
      if (id) {
        const { onResult } = useApolloQuery(
          useGetUserRolesQuery,
          { userId: id as string },
          // @ts-ignore, ignoring type error bc of pick
          {
            data: workerRoles,
            pending: rolesAreLoading,
          },
          {
            placeholderPick: ({ getCoreUserById: res }) =>
              res.employment?.workerRoles,
          }
        );

        onResult(({ getCoreUserById: res }) => {
          const roles = (res.employment?.workerRoles || []) as WorkerRole[];

          // No roles are assigned yet, assign a placeholder
          if (roles && !roles.length) {
            createRole(true);

            return;
          }

          workerRoles.value = roles;
        });

        if (unwatch) {
          unwatch();
        }
      }
    },
    { immediate: true }
  );
};

const useAddStaffForm = (props?, ctxEmit?, vueStore?) => {
  if (props) {
    emit = ctxEmit;
    store = vueStore;
    isEditing.value = props.isEditing;
    isPayrollUser.value = props.isPayrollUser;
    showBeginOnboarding.value = props.showBeginOnboarding;
    inviteAsAdmin.value = props.inviteAsAdmin;
    isSimplifiedForm.value = props.simplified;

    resetAddressForm();
    getWorkplaceOptions();
    getWorkerRoles();
    useAddStaffFormState(
      props.initialState,
      props.isPayrollUser,
      props.simplified,
      hasTimeAndAttendance.value
    );
    setAddressHookState(props.initialState, addressForm, isEditing.value);

    if (
      hasPayroll.value &&
      !isEditing.value &&
      !excludedIdList.value.includes(props.initialState.id) &&
      !isSimplifiedForm.value
    ) {
      formState.onboardToPayroll = true;
    }
  }

  return {
    addressForm,
    addressIsValid,
    addressLabels,
    emailLabel,
    filteredPayTypes,
    formState,
    handleSubmit,
    hasMultipleWorkplaces,
    hasPayroll,
    inviteAsAdmin,
    isEditing,
    isPayrollUser,
    isSavingWorker,
    phoneLabel,
    resetAdminState,
    rolesAreLoading,
    saveBtnLabel,
    showBeginOnboarding,
    v$,
    workerId,
    workerRoles,
    workplaceOptions,
    workerIsContractor,
    hasTimeAndAttendance,
  };
};

export default useAddStaffForm;
