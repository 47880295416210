import {
  watch,
  useRouter,
  useRoute,
  ref,
  computed,
  reactive,
} from '@nuxtjs/composition-api';
import sanitizeRoute from '@/utils/sanitizeRoute';

export interface Page {
  slug: string;
  label: string;
  id: string;
  hideBack?: boolean;
  hideNext?: boolean;
  nextLabel?: string;
  nextButtonProps?: Object;
  backLabel?: string;
  shouldShowBackBtn?: boolean;
  hideFooter?: boolean;
  progressOrder: number;
  // Overrides the default next button behavior
  onClick?: (fn: () => void) => void;
  // Does an async thing before the next action is called
  beforeNext?: () => void;
}

export type Route = { path: string } | undefined;

let init;
let router;
let route;
let BASE_URL;

const userPath = ref<Page[]>([]);
const oldStepId = ref();
const currentStepId = ref();

const isLoadingNext = ref(false);
const isNextDisabled = ref(false);

const currentStep = computed<Page | null>(() => {
  const matchingStep = userPath.value.find(
    ({ slug }) => slug === currentStepId.value
  );

  return matchingStep || userPath.value[0] || null;
});

const resetHook = () => {
  init = false;
  userPath.value = [];
  isLoadingNext.value = false;
  isNextDisabled.value = false;
  currentStepId.value = null;
  BASE_URL = null;
};

const completedFn = ref(() => {});

const onComplete = (fn: () => void) => {
  completedFn.value = fn;
};

const goNext = () => {
  const currentStepIndex = userPath.value.findIndex(
    (page) => page.slug === currentStepId.value
  );

  if (currentStepIndex === userPath.value.length - 1) {
    completedFn.value();

    return;
  }

  currentStepId.value = userPath.value[currentStepIndex + 1].slug;
  router.push(`${BASE_URL}/${currentStepId.value}`);
};

const next = () => {
  if (isLoadingNext.value) {
    return;
  }

  if (currentStep.value?.onClick) {
    currentStep.value.onClick(goNext);

    return;
  }

  if (currentStep.value?.beforeNext) {
    currentStep.value.beforeNext();
  }

  goNext();
};

const isFirstStep = computed(
  () => currentStepId.value === userPath.value[0].slug
);

const previous = () => {
  const currentStepIndex = userPath.value.findIndex(
    (page) => page.slug === currentStepId.value
  );

  if (isFirstStep.value) {
    return;
  }

  currentStepId.value = userPath.value[currentStepIndex - 1].slug;
  router.push(`${BASE_URL}/${currentStepId.value}`);
};

const setUserPath = (path: Page[] = []) => {
  userPath.value = path;
};

const setCurrentStep = (slug: string) => {
  currentStepId.value = slug;
};

const getPage = (pages: Page[], slug) =>
  pages.find((p) => p.slug.includes(slug));

const isTransitioning = ref(false);
const setTransitionFlag = () => {
  isTransitioning.value = true;
  // Total duration to match the base wizard animation
  setTimeout(() => {
    isTransitioning.value = false;
  }, 200);
};

const pageChanged = ref((_: Page) => {});
const onPageChange = (fn: (step: Page) => void) => {
  pageChanged.value = fn;
};

const useWizardPager = (baseUrl?: string, force = false) => {
  router = useRouter();
  route = useRoute();

  // Must be set on first init of the hook for a new context.
  if (baseUrl) {
    BASE_URL = baseUrl;
  }

  if (!init || force) {
    init = true;

    watch(
      [route, currentStep, userPath],
      ([newRoute, step, path], [oldRoute]) => {
        const oldSlug = sanitizeRoute((oldRoute as Route)?.path || '');
        const oldStepMatchingUrl = getPage(path as Page[], oldSlug);

        const newSlug = sanitizeRoute((newRoute as Route)?.path || '');
        const stepMatchingUrl = getPage(path as Page[], newSlug);

        setTransitionFlag();
        oldStepId.value = oldStepMatchingUrl?.slug || (path as Page[])?.[0]?.id;
        currentStepId.value = stepMatchingUrl?.slug as string;

        // If we're sent to a page that doesn't exist in the current flow,
        if (!stepMatchingUrl && step && BASE_URL) {
          router.push(`${BASE_URL}/${(step as Page)?.slug || ''}`);
        }

        if ((newRoute as Route)?.path !== (oldRoute as Route)?.path) {
          pageChanged.value(stepMatchingUrl as Page);
        }
      },
      { immediate: true }
    );
  }

  return {
    resetHook,
    next,
    previous,
    onComplete,
    setUserPath,
    currentStep,
    userPath,
    setCurrentStep,
    isLoadingNext,
    isNextDisabled,
    isFirstStep,
    onPageChange,
  };
};

export default useWizardPager;
