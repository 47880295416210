<template>
  <main id="main-content">
    <Portal v-if="showStepper" to="onboarding-header">
      <div class="px-6 py-3 text-center bg-white md:px-20 shadow-2">
        <!-- Progress stepper goes here. This is just a placeholder. -->
        <ProgressStepper
          :options="stepsToIncludeInStepper"
          :value="currentStep"
          :show-labels="$screen.md"
          class="max-w-2xl mx-auto justify-center"
        />
      </div>
    </Portal>
    <component
      :is="pageComponent"
      v-if="content"
      id="main-content"
      v-bind="pageContent"
      :content-map="contentMap"
      class="flex flex-col flex-grow"
    />
  </main>
</template>

<script>
/* eslint no-underscore-dangle: 0 */
import { ProgressStepper } from '@bambeehr/pollen';
import { mapActions, mapGetters, mapState } from 'vuex';
import { computed } from '@nuxtjs/composition-api';

import { QnaTypes } from '@/constants/Qna';
import OnboardingPages from '@/modules/OnboardingWizard/constants/OnboardingPages';

import ContentSlugs from '../constants/ContentSlugs';
import ContentTokens from '../constants/ContentTokens';
import fetchContent from '../services/fetchContent';
import useCurrentPlan from '@/hooks/useCurrentPlan/useCurrentPlan';
import usePlanAccess from '@/hooks/usePlanAccess/usePlanAccess';
import { useAdvisorsStore } from '@/store/advisors';

const BlankLoadingPage = () => import('../components/screens/BlankLoadingPage');
const Staff = () => import('../components/screens/AddStaff');
const CompanyAudit = () => import('../components/screens/CompanyAudit');
const HRRoadmap = () => import('../components/screens/HRRoadmap');
const IntroPage = () => import('../components/screens/IntroPage/IntroPage');
const OnboardingConfirmation = () =>
  import('../components/screens/OnboardingConfirmation');
const ReportStaff = () => import('../components/screens/ReportStaff');

const tiersThatSelfService = [0, 1, 2, 3];

export default {
  name: 'OnboardingWizard',
  components: { ProgressStepper },

  middleware: ['redirectFromOnboardingWizard'],
  setup() {
    const { isTierZero, tierNumber } = useCurrentPlan();

    const isSelfService = computed(() =>
      tiersThatSelfService.includes(tierNumber.value)
    );

    const advisorStore = useAdvisorsStore();
    const hrManager = computed(() => advisorStore.hrManager);
    const hrManagerAvatar = computed(() => advisorStore.hrManagerAvatar);
    const hrManagerFirstName = computed(() => advisorStore.hrManagerFirstName);

    return {
      isTierZero,
      isSelfService,
      hrManager,
      hrManagerAvatar,
      hrManagerFirstName,
    };
  },
  async asyncData() {
    const content = await fetchContent();

    return { content };
  },

  data() {
    return {
      isLoading: false,
      // We are skipping the HR intro call for now.
      isHrmIntroComplete: true,
    };
  },
  computed: {
    ...mapState({
      employees: (state) => state.users.staff,
    }),
    ...mapGetters({
      currentUser: 'currentUser',
      getSurvey: 'qna/byType',
      onboardingMilestones: 'companies/onboardingMilestones',
      allPlans: 'pricePlans/all',
    }),

    companyOverviewAudit() {
      return this.getSurvey(QnaTypes.COMPANY_OVERVIEW);
    },

    contentMap() {
      return {
        [ContentTokens.ADVISOR_NAME]: this.hrManagerFirstName,
        [ContentTokens.FIRST_NAME]: this.currentUser.profile?.first_name,
      };
    },
    currentStep() {
      return (
        OnboardingPages.find(({ slug }) => slug === this.$route.params.slug) ||
        OnboardingPages[0]
      ).slug;
    },
    pageComponent() {
      if (this.isLoading) {
        return BlankLoadingPage;
      }

      if (this.currentStep === OnboardingPages[1].slug) {
        return HRRoadmap;
      }

      if (this.currentStep === OnboardingPages[2].slug) {
        return CompanyAudit;
      }

      if (this.currentStep === OnboardingPages[3].slug) {
        return this.reportedStaffCount ? Staff : ReportStaff;
      }

      if (this.currentStep === OnboardingPages[4].slug) {
        return OnboardingConfirmation;
      }

      return IntroPage;
    },
    pageContent() {
      switch (this.pageComponent) {
        case IntroPage:
          return this.content[ContentSlugs.INTRO];
        case Staff:
          return {
            ...this.content[ContentSlugs.ADD_EMPLOYEES],
            advisorAvatar: this.hrManagerAvatar,
            employees: this.employees,
            staffCount: this.reportedStaffCount,
            surveyId: this.companyOverviewAudit?._id,
          };
        case ReportStaff:
          return {
            ...this.content[ContentSlugs.NO_STAFF],
            surveyId: this.companyOverviewAudit?._id,
          };
        case CompanyAudit:
          return {
            ...this.content[ContentSlugs.COMPANY_AUDIT],
            survey: this.companyOverviewAudit,
          };
        case OnboardingConfirmation:
          return this.hasAddedAllStaff
            ? this.content[ContentSlugs.CONFIRMATION]
            : this.content[ContentSlugs.CONFIRMATION_NO_STAFF];
        default:
          return {};
      }
    },

    hasAddedAllStaff() {
      return this.employees?.length >= this.reportedStaffCount;
    },

    reportedStaffCount() {
      return this.getEmployeeCountFromAudit(this.companyOverviewAudit);
    },

    stepsToIncludeInStepper() {
      const steps = OnboardingPages.filter((_, index) => {
        // removes first two steps and the last step if chat only - Tier 0
        // first step is the intro page
        // second step is the meet hr manager page
        // last step is the confirmation page
        if (!this.isSelfService) {
          // removes the business strategist step for t4+

          return (
            index !== 0 && index !== 1 && index !== OnboardingPages.length - 1
          );
        }

        // removes the first step and the last step if not chat only - Tier 1+
        return index !== 0 && index !== OnboardingPages.length - 1;
      })
        .filter((step) => {
          // Filters out the Add Staff step for T0 only
          return !(this.isTierZero && step.slug === 'add-staff');
        })
        .map((page) => {
          return {
            label: page.title,
            value: page.slug,
          };
        });

      return steps;
    },
    showStepper() {
      const { isBambeeLite } = usePlanAccess();

      return (
        !isBambeeLite.value &&
        this.stepsToIncludeInStepper.some(
          ({ value }) => this.currentStep === value
        )
      );
    },
  },
  async created() {
    this.isLoading = true;

    this.redirectToIncompleteMilestone();
    await this.fetchQnaByType(QnaTypes.COMPANY_OVERVIEW);
    await this.fetchEmployees();
    await this.completeHrmIntro();

    this.isLoading = false;
  },
  mounted() {},
  methods: {
    ...mapActions({
      fetchEmployees: 'users/getSimplifiedMyEmployees',
      fetchQnaByType: 'qna/fetchByType',
      updateOnboardingMilestone: 'companies/updateOnboardingMilestone',
    }),
    getEmployeeCountFromAudit(audit) {
      if (!audit?.answers?.employeeBreakdown) {
        return 0;
      }

      const keys = Object.keys(audit.answers.employeeBreakdown);

      return keys.reduce((acc, key) => {
        return acc + (audit.answers.employeeBreakdown[key] || 0);
      }, 0);
    },

    async completeHrmIntro() {
      // Go ahead and complete the milestones that would cause an infinite loop
      // during onboarding if not complete.

      await this.updateOnboardingMilestone({
        milestone: 'AcknowledgeHrm',
        completed: true,
      });

      this.isHrmIntroComplete = true;
    },

    async redirectToIncompleteMilestone() {
      const { AddStaff, AboutCompany, WelcomeCall } =
        this.onboardingMilestones.reduce((acc, m) => {
          return {
            ...acc,
            [m.milestone]: m,
          };
        }, {});

      // if the customer is chat only and the required milestones are complete redirect to the let's go page
      if (this.isHrmIntroComplete && AboutCompany.status && AddStaff.status) {
        this.$router.push(`/get-started/${OnboardingPages[4].slug}`);

        return;
      }

      if (WelcomeCall.status) {
        this.$router.push(`/get-started/${OnboardingPages[2].slug}`);
      }

      if (AboutCompany.status) {
        // Skip AddStaff screen for T0 customers who have no staff

        // If Company QNA milestone is complete, add 1 to onboardingStep
        // onboardingStep should be 3, which means the customer
        // will always be auto-redirected to the AddStaff screen
        if (this.isTierZero) {
          this.$router.push(`/get-started/${OnboardingPages[4].slug}`);
        } else {
          this.$router.push(`/get-started/${OnboardingPages[3].slug}`);
        }
      }

      if (AddStaff.status) {
        this.$router.push(`/get-started/${OnboardingPages[4].slug}`);
      }
    },
  },
  layout: 'onboarding',
};
</script>

<style scoped>
.progress-stepper >>> button {
  @apply outline-none;
}
</style>
