import Store from '@/constants/Store';
import { OnboardingMilestoneTag } from '@/modules/OnboardingWizard/constants/OnboardingMilestoneTag';

const hasCompletedSomeMilestones = (store) => {
  const onboardingMilestones = store.getters[
    Store.companies.Getters.ONBOARDING_MILESTONES_BY_TAG
  ](OnboardingMilestoneTag.INTRO);

  // AcknowledgeHrm is a milestone that is completed upon account creation.
  return onboardingMilestones?.some(
    (m) => !!m.status && m.milestone !== 'AcknowledgeHrm'
  );
};

export default hasCompletedSomeMilestones;
