import {
  Milestones,
  MilestoneTags,
} from '@/modules/TaskCenter/types/Milestones';

let hasPractice: boolean;

/**
 * Defines access to HR Practices and Business health
 *
 * If a client has ever had HR Practices enabled,
 * they should  NOT have access to Business Health
 *
 * @param store
 */
const hadHrPracticeEnabledEver = async (store) => {
  // Respond if checked milestones and allowed already
  if (hasPractice) {
    return hasPractice;
  }

  const milestones = store.getters['companies/onboardingMilestonesByTag'](
    MilestoneTags.HR_PRACTICE
  );

  hasPractice = (milestones || []).some(
    (m) => m.milestone === Milestones.HR_PRACTICE
  );

  return hasPractice;
};

export default hadHrPracticeEnabledEver;
