import { useApolloQuery } from '@/gql/apolloWrapper';
import useCurrentCompany from '@/hooks/useCurrentCompany';

import {
  CompanyBenefitOnboardStatus,
  GetCompanyOnboardingStatusQuery,
  useGetCompanyOnboardingStatusQuery,
  useGetCompanyStatusQuery,
} from '@/gql/generated';
import { computed, ref, watch } from '@nuxtjs/composition-api';

const company = ref<GetCompanyOnboardingStatusQuery>();

const hasTimeAndAttendance = computed(
  () =>
    !!company.value?.getCompany?.deputyConnection?.hasAccessToken &&
    !!company.value?.getCompany?.deputyConnection?.validatedAt
);

const getCompanyStatus = () => {
  const { companyId } = useCurrentCompany();

  watch(
    companyId,
    (id) => {
      if (id) {
        useApolloQuery(
          useGetCompanyStatusQuery,
          { id },
          { data: company },
          // Silent fail if payroll company is not found.
          { handleErrors: false }
        );
      }
    },
    { immediate: true }
  );
};

const useEmployerTimeAndAttendanceAccess = () => {
  getCompanyStatus();

  return {
    hasTimeAndAttendance,
  };
};

export default useEmployerTimeAndAttendanceAccess;
