import { defineStore } from 'pinia';
import HrRoles from '@/constants/HrRoles';
import get from 'lodash/get';

export interface AdvisorUser {
  avatarUrl: string;
  calendlySlug: string;
  id: string;
  name: string;
  yearsExperience: string;
}

export interface Advisor {
  id: string;
  isPrimary: boolean;
  // TODO replace with enum
  role: string;
  previous: AdvisorUser;
  user: AdvisorUser;
}

// This defines the sort order for advisors.
// TODO(jon.jandoc): We should consider making a library of roles similar to
// lib/roles in Honey that can be shared across projects.
const ORDERED_ROLES = ['hrm', 'obs', 'ac', 'oc', 'hbs', 'bm', 'pym'];

const getFirstName = (fullName) => fullName.split(' ')[0];

const getAdvisorName = (advisor, defaultName = 'HR Manager') =>
  get(advisor, 'user.name', defaultName);

const getAdvisorAvatar = (
  advisor,
  defaultAvatar = '/images/blank/blank-person.png'
) => get(advisor, 'user.avatarUrl') || defaultAvatar;

export const useAdvisorsStore = defineStore('advisors', {
  state: (): { all: any[]; primaryAdvisorTimeSlots: any[] } => ({
    all: [],
    primaryAdvisorTimeSlots: [],
  }),
  getters: {
    hrAdvisors(state): Advisor[] {
      return state.all.filter(
        (advisor) => advisor.role !== 'ae' && advisor.role !== 'insurance'
      );
    },

    accountExecutive(state): Advisor {
      return state.all.find((advisor) => advisor.role === 'ae');
    },

    primaryAdvisor(state): Advisor {
      return this.all.find((advisor) => advisor.isPrimary) || {};
    },

    primaryAdvisorName(state): string {
      return getAdvisorName(this.primaryAdvisor);
    },

    primaryAdvisorFirstName(): string {
      return getFirstName(this.primaryAdvisorName);
    },

    primaryAdvisorAvatar(): string {
      return getAdvisorAvatar(this.primaryAdvisor);
    },

    hrManager(state): Advisor {
      return state.all.find((advisor) => advisor.role === HrRoles.HRM);
    },

    hrManagerName(): string {
      return getAdvisorName(this.hrManager);
    },

    hrManagerFirstName(): string {
      return getFirstName(this.hrManagerName);
    },

    hrManagerAvatar(): string {
      return getAdvisorAvatar(this.hrManager);
    },

    businessManager(state): Advisor {
      return state.all.find((advisor) => advisor.role === HrRoles.BM);
    },

    businessManagerName(): string {
      return getAdvisorName(this.businessManager);
    },

    businessManagerFirstName(): string {
      return getFirstName(this.businessManagerName);
    },

    businessManagerAvatar(): string {
      return getAdvisorAvatar(this.businessManager);
    },
  },
  actions: {
    setAdvisors(advisors) {
      this.all = advisors
        .filter((advisor) => advisor.user)
        .sort((a, b) => {
          if (a.isPrimary || !ORDERED_ROLES.includes(b.role)) {
            return -1;
          }
          if (b.isPrimary || !ORDERED_ROLES.includes(a.role)) {
            return 1;
          }

          return ORDERED_ROLES.indexOf(a.role) - ORDERED_ROLES.indexOf(b.role);
        });
    },
    getAdvisors(companyId) {
      return this.$nuxt.$axios
        .get(`/v0/companies/v1/company/${companyId}/advisors`)
        .then((res) => this.setAdvisors(res.data));
    },
    setPrimaryAdvisorTimeSlots(availableSlots) {
      this.primaryAdvisorTimeSlots = availableSlots;
    },
  },
});
