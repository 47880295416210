






















import { defineComponent } from '@nuxtjs/composition-api';
import { isProd } from '@/utils/env-helper';
import BaseNotifications from '@/components/BaseNotifications/BaseNotifications.vue';
import ErrorBoundary from '@/modules/payroll/components/ErrorBoundary/ErrorBoundary.vue';

export default defineComponent({
  name: 'OnboardingLayout',
  components: {
    BambeeDevTools: () => import('@/components/BambeeDevTools/BambeeDevTools'),
    BaseNotifications,
    ErrorBoundary,
  },

  setup() {
    return {
      isProd,
    };
  },
});
