











































































import CompanyGoal from './CompanyGoal.vue';
import { defineComponent, ref, computed, watch } from '@nuxtjs/composition-api';
import FileUploadModal from '@/components/FileUploadModal';
import {
  SelectInput,
  TypeBody,
  BaseBanner,
  AnnularThrobber,
} from '@bambeehr/pollen';
import useNotifications from '@bambeehr/use-notifications';
import {
  useGetCompanyGoalsQuery,
  Goal,
  useUploadRequirementFileMutation,
  DocumentOptionType,
} from '@/gql/generated';
import CachePolicy from '@/gql/CachePolicy';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import { useApolloQuery, useApolloMutation } from '@/gql/apolloWrapper';
import cloneDeep from 'lodash/cloneDeep';

const getBase64File = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      // reader.result is a data url, so we split it to get the base64 string
      resolve((reader.result as string)?.split(',')?.[1] as string);
    reader.onerror = (error) => reject(error);
  });

const getCompanyGoals = (companyId: string, goals, loading) => {
  useApolloQuery(
    useGetCompanyGoalsQuery,
    { id: companyId },
    // @ts-ignore
    {
      data: goals,
      pending: loading,
    },
    {
      placeholderPick: ({ getCompany }) =>
        getCompany.goals.filter((g) => g.requirements.length > 0),
    }
  );
};

export interface BannerError {
  message: string;
  description: string;
}

export default defineComponent({
  name: 'CompanyGoals',
  components: {
    AnnularThrobber,
    BaseBanner,
    CompanyGoal,
    FileUploadModal,
    SelectInput,
    TypeBody,
  },
  setup() {
    const { companyId } = useCurrentCompany();
    const { addError, addSuccess } = useNotifications();

    const isSaving = ref(false);
    const { mutate: uploadFile, onDone: uploadDone } = useApolloMutation(
      useUploadRequirementFileMutation,
      { pending: isSaving }
    );

    const uploadModalVisible = ref(false);
    const currentRequirement = ref();
    const selectedFileType = ref();
    const uploads = ref([]);
    const goals = ref<Goal[]>([]);
    const isLoading = ref(false);

    const formError = ref<BannerError | null>(null);
    const uploaderError = ref('');
    const uploadTypeError = ref('');

    const resetErrors = () => {
      uploaderError.value = '';
      uploadTypeError.value = '';
    };

    const resetForm = () => {
      uploads.value = [];
      selectedFileType.value = '';
    };

    const toggleUploadModal = (info?) => {
      currentRequirement.value = info;
      uploadModalVisible.value = !!info;

      if (!info) {
        resetForm();
      }
    };

    const handleFile = (files) => {
      resetErrors();
      uploads.value = files;
    };

    getCompanyGoals(companyId.value, goals, isLoading);

    const formIsInvalid = computed(
      () => !uploads.value?.[0] || !selectedFileType.value
    );

    const fileMaxCount = computed(
      // If it's a bank statement, we should upload 2 files to Check
      () =>
        selectedFileType.value === DocumentOptionType.BankStatement ? 2 : 1
    );

    const fileTypeOptions = computed(() =>
      currentRequirement.value?.documentOptions.map((option) => ({
        label: option.name,
        value: option.id,
      }))
    );

    // Used for setting the disabled state
    const onlyOneFileTypeOption = computed(
      () => fileTypeOptions.value?.length === 1
    );

    // If there is only one file type option available, select it
    watch(fileTypeOptions, (ops) => {
      if (ops?.length === 1) {
        selectedFileType.value = ops[0].value;
      }
    });

    watch(fileMaxCount, (type) => {
      // If selected max file count changes, reset the uploads
      uploads.value = [];
    });

    const hasNoGoals = computed(
      () =>
        goals.value.every((goal) => !goal.requirements?.length) ||
        !goals.value.length
    );

    const handleSubmit = async () => {
      resetErrors();

      if (formIsInvalid.value) {
        if (!selectedFileType.value) {
          uploadTypeError.value = 'Required field.';
        }

        if (!uploads.value?.[0]) {
          uploaderError.value = 'Please upload a document.';
        }

        return;
      }

      try {
        isSaving.value = true;
        const allUploads = cloneDeep(uploads.value);

        const files = await Promise.all(
          allUploads.map(async ({ file }: { file: File }) => {
            const base64File = await getBase64File(file);

            return {
              name: file.name,
              file: base64File,
              type: selectedFileType.value,
            };
          })
        );

        uploadFile({
          data: {
            files,
            requirementId: +currentRequirement.value.id,
            companyId: companyId.value,
          },
        });
      } catch (error) {
        addError(error);
        toggleUploadModal();
      }
    };

    uploadDone(() => {
      toggleUploadModal();
      getCompanyGoals(companyId.value, goals, isLoading);
      resetForm();
      addSuccess('Document submitted');
    });

    watch(selectedFileType, () => {
      resetErrors();
    });

    return {
      goals,
      toggleUploadModal,
      uploads,
      uploadModalVisible,
      handleFile,
      selectedFileType,
      currentRequirement,
      handleSubmit,
      uploaderError,
      uploadTypeError,
      fileTypeOptions,
      isSaving,
      formError,
      hasNoGoals,
      onlyOneFileTypeOption,
      isLoading,
      fileMaxCount,
    };
  },
});
