import useCurrentPlan from '@//hooks/useCurrentPlan/useCurrentPlan';
import { PayrollTierSegmentEvents } from '@/components/ProductSelector/const/segmentEvents';
import { useApolloMutation, useApolloQuery } from '@/gql/apolloWrapper';
import {
  AvailableProduct,
  BillingPeriod,
  PayrollPlanModel,
  ProductKey,
  useAddSubscriptionMutation,
  useCreatePayrollTierSubscriptionMutation,
  useGetAvailableProductsQuery,
  useGetEligibleAddonsQuery,
  usePurchaseNectarCreditsMutation,
} from '@/gql/generated';
import { GET_ELIGIBLE_ADDONS } from '@/gql/queries/payroll_queries.gql';
import useCompanyBillingInfo from '@/hooks/useCompanyBillingInfo';
import useCurrentCompany from '@/hooks/useCurrentCompany';
import usePaymentMethods from '@/modules/Settings/hooks/usePaymentMethods';
import { formatCurrency } from '@/utils/currency';
import isAdmin from '@/utils/isAdmin';
import { TypeDisplay } from '@bambeehr/pollen';
import useNotifications from '@bambeehr/use-notifications';
import { computed, ComputedRef, ref } from '@nuxtjs/composition-api';
import useProductCopy from './const/productCopy';

export enum PriceTerms {
  MONTHLY = 'month',
  YEARLY = 'year',
}

export enum WebinerEvents {
  WEBINAR_PAGE_VIEW = 'WEBINAR_PAGE_VIEW',
  WEBINAR_PURCHASE_MODAL_OPENED = 'WEBINAR_PURCHASE_MODAL_OPENED',
  WEBINAR_PURCHASED = 'WEBINAR_PURCHASED',
  WEBINAR_SCHEDULED = 'WEBINAR_SCHEDULED',
  WEBINAR_ACH_ADDED = 'WEBINAR_ACH_ADDED',
  WEBINAR_VIEWED_ACH_FORM = 'WEBINAR_VIEWED_ACH_FORM',
}

let track;

const isLoading = ref(false);
const isSubscription = ref(true);
const displayProduct = ref();
const allProducts = ref<AvailableProduct[]>([]);
const eligibleAddOns = ref<PayrollPlanModel[]>([]);
const shouldShowPaymentMethodModal = ref(false);
const showPurchaseModal = ref(false);
const employeeSizeRange = ref();
const currentTier = ref<number>();
const showAddOns = ref(false);

const { copy, hasACH, isAboveTierThreshold } = useProductCopy();
const setupHook = (force = false) => {
  const { companyId } = useCurrentCompany();
  const { currentPlan } = useCurrentPlan();
  const { payrollTrackEvent } = useCompanyBillingInfo();
  track = payrollTrackEvent;
  employeeSizeRange.value = currentPlan.value?.employeeSizeMapping;
  currentTier.value = currentPlan.value?.tierNumber;

  if (isAdmin.value) {
    const { onResult: onEligibleAddonsResult } = useApolloQuery(
      useGetEligibleAddonsQuery,
      {
        input: {
          companyId: companyId.value,
        },
      },
      undefined,
      { force }
    );

    onEligibleAddonsResult(({ getEligibleAddons: res }) => {
      eligibleAddOns.value = res?.payroll;
    });

    const { onResult } = useApolloQuery(
      useGetAvailableProductsQuery,
      {
        data: {
          companyId: companyId.value,
        },
      },
      undefined,
      { force }
    );

    onResult(({ getAvailableProducts: res }) => {
      allProducts.value = res;
      isLoading.value = false;
    });
  }
};

const refresh = () => {
  setupHook(true);
};

const productAllowance = computed(
  () =>
    displayProduct.value !== ProductKey.WebinarWorkplaceViolence &&
    allProducts.value.find((p) => p.key === displayProduct.value)
      ?.monthlyAllowance
);

const currentCopy = computed(() =>
  displayProduct.value ? copy.value[displayProduct.value] : null
);

const currentTermModel = ref(BillingPeriod.Month);
export enum PurchaseOptions {
  ACH = 'ach',
  ONE_TIME = 'one-time',
  WEBINAR_ONE_TIME = 'webinar-one-time',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
  WEBINAR_YEARLY = 'webinar-yearly',
  BASIC = 'basic',
  PREMIUM = 'premium',
}
export interface ProductOption {
  id: PurchaseOptions;
  key?: ProductKey;
  label: string;
  labelComponent?: any;
  monthlyPrice: number | null;
  formattedMonthlyPrice?: string | null;
  price: number;
  formattedPrice?: string;
  value: BillingPeriod;
  savingPercent: number;
  description?: string;
  discountPrice?: string;
  hasDiscount?: boolean;
  disabled?: boolean;
  itemValue?: string;
  secondaryDescription?: string;
}

export const formatPrice = (price) => formatCurrency(price / 100);

const basicPayrollTier: ComputedRef<PayrollPlanModel> = computed(
  () =>
    eligibleAddOns.value?.find(
      (p) => p.productKey === ProductKey.PayrollBasic
    ) as PayrollPlanModel
);
const premiumPayrollTier: ComputedRef<PayrollPlanModel> = computed(
  () =>
    eligibleAddOns.value?.find(
      (p) => p.productKey === ProductKey.PayrollPremium
    ) as PayrollPlanModel
);

const basicOption = computed(() => {
  return {
    id: PurchaseOptions.BASIC,
    key: basicPayrollTier.value?.productKey,
    labelComponent: TypeDisplay,
    label:
      '<TypeDisplay variant="tiny" class="font-bold">Payroll</TypeDisplay> <TypeDisplay variant="tiny" class="text-secondary-default font-normal">Basic</TypeDisplay>',
    monthlyPrice: basicPayrollTier.value?.price || 0,
    formattedMonthlyPrice: formatCurrency(basicPayrollTier.value?.price) || '',
    price: basicPayrollTier.value?.price || 0,
    formattedPrice: formatCurrency(basicPayrollTier.value?.price),
    value: BillingPeriod.Month,
    savingPercent: 0,
    description: `plus $${basicPayrollTier.value.unitPrice}/employee/mo`,
    hasDiscount: false,
    secondaryDescription: 'plus $4/employee/mo for time and attendance¹',
    itemValue: basicPayrollTier.value?.value,
  };
});

const premiumOption = computed(() => {
  return {
    id: PurchaseOptions.PREMIUM,
    key: premiumPayrollTier.value?.productKey,
    labelComponent: TypeDisplay,
    label:
      '<TypeDisplay variant="tiny" class="font-bold">Payroll</TypeDisplay> <TypeDisplay variant="tiny" class="text-premium-gold font-normal">Premium</TypeDisplay>',
    monthlyPrice: premiumPayrollTier.value?.price || 0,
    monthlyFormattedPrice: formatCurrency(premiumPayrollTier.value?.price || 0),
    price: premiumPayrollTier.value?.price || 0,
    formattedPrice: formatCurrency(premiumPayrollTier.value?.price || 0),
    value: BillingPeriod.Year,
    hasDiscount: false,
    savingPercent: 0,
    description: `plus $${premiumPayrollTier.value.unitPrice}/employee/mo`,
    secondaryDescription: 'plus FREE time and attendance',
    itemValue: premiumPayrollTier.value?.value,
  };
});

const currentProductOptions = computed<ProductOption[]>(() => {
  if (showAddOns.value) {
    const options = [] as ProductOption[];

    if (basicPayrollTier.value?.value) {
      options.push(basicOption.value);
    }

    if (premiumPayrollTier.value?.value) {
      options.push(premiumOption.value);
    }

    return options;
  }

  const filtered = allProducts.value.filter(
    (p) => p.key === displayProduct.value
  );

  if (filtered.length === 0) {
    return [];
  }

  if (displayProduct.value === ProductKey.WebinarWorkplaceViolence) {
    const item = filtered.find(
      (p) =>
        p.billingPeriod === PriceTerms.YEARLY &&
        p.tier === (currentTier.value ?? 1)
    );
    if (!item) {
      return [];
    }

    currentTermModel.value = BillingPeriod.Year;
    const webinarLabel = employeeSizeRange.value
      ? `Price for ${employeeSizeRange.value} employees`
      : 'Price for one year';
    const webinarPurchase = {
      id: PurchaseOptions.WEBINAR_YEARLY,
      label: webinarLabel,
      labelComponent: TypeDisplay,
      monthlyPrice: null,
      price: item.subscriptionPrice ?? 0,
      formattedMonthlyPrice: formatPrice(item.subscriptionPrice ?? 0), // not actually the monthly price, but this value is used to display the price
      value: BillingPeriod.Year,
      savingPercent: 0,
      description: '',
      chargeDescription: `You will be charged ${formatPrice(
        item.subscriptionPrice ?? 0
      )} + tax on an annual recurring basis.`,
    };

    const priceOptions: ProductOption[] = [webinarPurchase];

    return priceOptions;
  }

  if (isSubscription.value) {
    const monthly = filtered.find(
      (p) => p.billingPeriod === PriceTerms.MONTHLY
    );
    const yearly = filtered.find((p) => p.billingPeriod === PriceTerms.YEARLY);

    const formatPrice = (price: number, divisor = 100) =>
      formatCurrency(price / divisor, 2);

    return [
      {
        id: PurchaseOptions.MONTHLY,
        label: 'Monthly',
        labelComponent: TypeDisplay,
        monthlyPrice: monthly?.subscriptionPrice ?? 0,
        formattedMonthlyPrice: formatPrice(monthly?.subscriptionPrice ?? 0),
        price: monthly?.subscriptionPrice ?? 0,
        formattedPrice: formatPrice(monthly?.subscriptionPrice ?? 0),
        value: BillingPeriod.Month,
        savingPercent: 0,
        description: 'per month',
      },
      {
        id: PurchaseOptions.YEARLY,
        label: 'Annual',
        labelComponent: TypeDisplay,
        monthlyPrice: (yearly?.subscriptionPrice ?? 0) / 12,
        formattedMonthlyPrice: formatPrice(
          (yearly?.subscriptionPrice ?? 0) / 12
        ),
        price: yearly?.subscriptionPrice ?? 0,
        formattedPrice: formatPrice((yearly?.subscriptionPrice ?? 0) / 12),
        value: BillingPeriod.Year,
        savingPercent: 20,
        description: 'per month billed annually',
      },
    ];
  }

  return [];
});

const currentProductTermInfo = computed(() => {
  if (!currentProductOptions.value) {
    return null;
  }

  return currentProductOptions.value.find(
    (p) => p.value === currentTermModel.value
  );
});

const isSubscribing = ref(false);
const onSubscribed = ref();

const handleAddPayrollTier = async (productOption) => {
  const { addSuccess } = useNotifications();
  const { companyId } = useCurrentCompany();

  return new Promise((resolve, reject) => {
    const { onDone, mutate } = useApolloMutation(
      useCreatePayrollTierSubscriptionMutation,
      {
        pending: isSubscribing,
      }
    );

    mutate(
      {
        data: {
          companyId: companyId.value,
          planValue: productOption.itemValue,
        },
      },
      {
        refetchQueries: [
          {
            query: GET_ELIGIBLE_ADDONS,
            variables: {
              input: { companyId: companyId.value },
            },
          },
        ],
      }
    );

    onDone((res) => {
      addSuccess(`${currentCopy.value.title} subscription added!`);

      track(PayrollTierSegmentEvents.TIER_PUCHASE_COMPLETED, {
        companyId: companyId.value,
        planValue: productOption.itemValue,
      });
      refresh();
      resolve(res);
    });
  });
};

const handlePurchase = async (productOption) => {
  const { addSuccess } = useNotifications();
  const isPayroll = currentProductOptions.value.some(
    (p) =>
      p.key === ProductKey.PayrollBasic || p.key === ProductKey.PayrollPremium
  );
  track('purchase-started', productOption);
  if (isPayroll) {
    // Purchase payroll
    await handleAddPayrollTier(productOption);

    return;
  }
  if (isSubscription.value) {
    const { onDone, mutate } = useApolloMutation(useAddSubscriptionMutation, {
      pending: isSubscribing,
    });
    onDone(({ purchaseSubscription: res }) => {
      addSuccess(`${currentCopy.value.title} subscription added!`);
      onSubscribed?.value(res);
    });
    mutate({
      data: {
        productKey: displayProduct.value,
        billingPeriod: currentTermModel.value,
      },
    });

    return;
  }

  const { onDone, mutate } = useApolloMutation(
    usePurchaseNectarCreditsMutation,
    {
      pending: isSubscribing,
    }
  );

  onDone(({ purchaseCredits: res }) => {
    addSuccess(`${currentCopy.value.title} subscription added!`);
    onSubscribed?.value(res);
  });

  mutate({
    data: {
      productKey: displayProduct.value,
      quantity: 1,
    },
  });
};

const handleCTAClicked = async (productOption) => {
  if (productOption.id === PurchaseOptions.ACH && !hasACH.value) {
    showPurchaseModal.value = false;
    shouldShowPaymentMethodModal.value = true;

    track(WebinerEvents.WEBINAR_VIEWED_ACH_FORM);

    return;
  }
  await handlePurchase(productOption);
};

let init;

const showPaymentMethodModal = () => {
  shouldShowPaymentMethodModal.value = true;
  track(WebinerEvents.WEBINAR_VIEWED_ACH_FORM);
};

const handleAdd = () => {
  const { refetch: getBillingInfoRefetch } = usePaymentMethods();

  getBillingInfoRefetch();
  refresh();
  shouldShowPaymentMethodModal.value = false;
  showPurchaseModal.value = true;
  track(WebinerEvents.WEBINAR_ACH_ADDED);
};

const useProductSelector = (product?: ProductKey, isSub?: boolean) => {
  if (product) {
    displayProduct.value = product;
    isSubscription.value = isSub ?? true;
  }
  if (!init) {
    setupHook();
    init = true;
  }

  return {
    isLoading,
    currentCopy,
    currentProductOptions,
    currentTermModel,
    productAllowance,
    currentProductTermInfo,
    onSubscribed,
    handlePurchase,
    isSubscribing,
    isSubscription,
    refresh,
    shouldShowPaymentMethodModal,
    showPaymentMethodModal,
    handleAdd,
    showPurchaseModal,
    hasACH,
    handleCTAClicked,
    employeeSizeRange,
    displayProduct,
    showAddOns,
    basicPayrollTier,
    premiumPayrollTier,
  };
};

export default useProductSelector;
